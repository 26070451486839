// src/components/Home.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import chatBackgroundImage from "../../assets/images/dashboard.jpg";
import axios from "axios";
import img from "../../assets/images/coming-soon.png";

export default function Credentials() {
  // Funtion to handle the fetching of subjects
  const [skill, setSkill] = useState("");
  const [skills, setSkills] = useState([]);

  const handleAddSkill = () => {
    if (skill.trim()) {
      setSkills([...skills, skill]);
      setSkill("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddSkill();
    }
  };
  return (
    <div className="flex items-center justify-center h-screen p-8 mx-auto mr-8">
      <div
        style={{ width: "50%", height: "85%" }}
        className="bg-white border-2 border-stone-400 rounded-md p-5 shadow-lg flex flex-col justify-center items-center"
      >
        <h1 className="font-extrabold text-3xl text-center text-gray-800 mt-4">
          Employer Portal is Coming Soon!
        </h1>
        <h2 className="mt-4 text-lg text-gray-600 text-center">
          We're working hard to bring you a brand new experience. Stay tuned for
          updates as we get closer to launch. We can't wait to share what we've
          been working on!
        </h2>
        <img src={img} className="w-56 h-48 mt-6" alt="Coming Soon" />
      </div>
    </div>
  );
}
