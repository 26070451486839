import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useCUser } from "./contexts/Cusercontext"; // Adjust the path as needed
import img from "./assets/images/access-denied.png";

const EmployerRoute = ({ children }) => {
  const { usertype } = useCUser();
  const [timer, setTimer] = useState(false);
  const [redirectPath, setRedirectPath] = useState(false);

  console.log(usertype);

  useEffect(() => {
    if (
      usertype !== "superadmin" &&
      usertype !== "admin" &&
      usertype !== "readonlyadmin" &&
      usertype !== "employer"
    ) {
      setTimer(true);
      setTimeout(() => {
        setRedirectPath("/dashboard");
        // Reset timer state after redirection
      }, 7000);
    }
  }, [usertype]);

  if (redirectPath) {
    return <Navigate to={redirectPath} />; // Redirect if needed
  }

  if (timer) {
    return (
      <div>
        {/* Layout for small and medium screens */}
        <div className="block lg:hidden ml-4 mx-3 p-2 rounded-md dark:bg-gray-800 dark:text-white">
          <div className="flex flex-col gap-2 ml-2 mt-8 ">
            <div className="md:w-9/12 flex flex-col">
              <h1 className="text-4xl md:text-7xl font-medium leading-tighter tracking-tighter mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                  Access Denied
                </span>
              </h1>
              <div className="max-w-3xl mx-auto">
                <p className="text-xl text-gray-600 dark:text-gray-400 mb-4">
                  You dont have access to enter this page. Perhaps it was an
                  accident or you were trying to access a restricted URL
                </p>
                <p className="text-xl font-medium text-gray-600 dark:text-gray-400 mb-8">
                  Either way, you will be redirected back to Guest Mode in a few
                  seconds.
                </p>
              </div>
              <img
                src={img}
                alt="Chat Background"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>

        <div className="hidden lg:block p-20 ml-4 mr-4 rounded-md mb-8 mt-12 ">
          <div className="flex flex-row gap-8">
            <div className="md:w-9/12 flex flex-col">
              <h1 className="text-8xl md:text-7xl font-medium leading-tighter tracking-tighter mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                  Access Denied
                </span>
              </h1>
              <div className="max-w-3xl mx-auto">
                <p className="text-3xl text-gray-600 dark:text-gray-400 mb-4">
                  You dont have access to enter this page. Perhaps it was an
                  accident or you were trying to access a restricted URL
                </p>
                <p className="text-3xl font-medium text-gray-600 dark:text-gray-400 mb-8">
                  Either way, you will be redirected back to Guest Mode in a few
                  seconds.
                </p>
              </div>
            </div>
            <div className="md:w-5/12 md:mt-1/2 md:ml-12 mt-8">
              <img
                src={img}
                alt="Chat Background"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>

          <div className="flex flex-rowgap-2"></div>
        </div>
      </div>
    );
  }

  return children; // Render the children if the user is an admin
};

export default EmployerRoute;
