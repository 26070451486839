import React, { useState } from 'react';
import { Link } from 'react-router-dom'

export default function SignUp() {
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    setFormData(
      {
        ...formData,
        [e.target.id]:e.target.value,
      }
    )
  };
  const handleSubmit =  async(e) => {
    e.preventDefault();
    const res = await fetch('http://localhost:5000/api/auth/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify(formData),
    });
    const data = await res.json();
    console.log(data);
  }
  console.log(formData);
  return (
    <div className='p-s max-w-lg mx-auto'>
       <div className=' w-grow mr-4 ml-4 rounded-md lg:h-20 xl:h-64'>
      </div>
      <h1 className='lg:text-2xl xl:text-3xl text-centre font-semibold lg:my-4 xl:my-8 lg:ml-52 xl:ml-48 pr-40'>Sign Up</h1>
      <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
        <input type="text" placeholder='Name' className='border lg:text-sm xl:text-m lg:p-2 xl:p-3 lg:rounded-md xl:rounded-lg' id='first_name' onChange={handleChange} />
        <input type="email" placeholder='Email' className='border lg:text-sm xl:text-m lg:p-2 xl:p-3 lg:rounded-md xl:rounded-lg' id='email' onChange={handleChange}/>
        <input type="password" placeholder='Password' className='border lg:text-sm xl:text-m lg:p-2 xl:p-3 lg:rounded-md xl:rounded-lg' id='password' onChange={handleChange}/>
        <input type="password" placeholder='Confirm Password' className='border lg:text-sm xl:text-m lg:p-2 xl:p-3 lg:rounded-md xl:rounded-lg' id='password' onChange={handleChange}/>
        <button style={{ backgroundColor: '#23262B' }} className='h-10 lg:text-sm xl:text-m text-white lg:p-2 xl:p-3 lg:rounded-md xl:rounded-lg uppercase hover:opacity-95 disabled:opacity-80'> Sign Up</button>
      </form>
      <div className='flex flex-row gap-2 mt-5'>
        <p>Have an account?</p>
        <Link to={"/sign-in"} className='text-blue-600'>
          Sign In
        </Link>
      </div>
      <div className='flex flex-row gap-2 align-center'>
        <hr className='w-64 h-1 rounded-md mt-4 bg-black'></hr>
        <h1 className='mt-1'>Or</h1>
        <hr className='w-64 h-1 rounded-md mt-4 bg-black'></hr>
      </div>
      <div className='flex flex-row'>
          <button className='flex flex-row w-64 gap-2 items-center justify-center p-2 rounded-md bg-orange-200 hover:bg-orange-300 mt-4'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 32 32">
              <path d="M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z"></path>
            </svg>
            Google
          </button>
          <button className='flex flex-row w-64 gap-2 items-center justify-center p-2 rounded-md bg-sky-200 hover:bg-sky-300 ml-2 mt-4'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
              <path d="M36.5,6h-25C8.468,6,6,8.468,6,11.5v25c0,3.032,2.468,5.5,5.5,5.5h25c3.032,0,5.5-2.468,5.5-5.5v-25	C42,8.468,39.532,6,36.5,6z M18,34c0,0.553-0.447,1-1,1h-3c-0.553,0-1-0.447-1-1V21c0-0.553,0.447-1,1-1h3c0.553,0,1,0.447,1,1V34z M15.5,18c-1.381,0-2.5-1.119-2.5-2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C18,16.881,16.881,18,15.5,18z M35,34	c0,0.553-0.447,1-1,1h-3c-0.553,0-1-0.447-1-1v-7.5c0-1.379-1.121-2.5-2.5-2.5S25,25.121,25,26.5V34c0,0.553-0.447,1-1,1h-3	c-0.553,0-1-0.447-1-1V21c0-0.553,0.447-1,1-1h3c0.553,0,1,0.447,1,1v0.541C26.063,20.586,27.462,20,29,20c3.309,0,6,2.691,6,6V34z"></path>
            </svg>
            LinkedIn</button>
        </div>
      
    </div>
  )
}
