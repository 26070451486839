// src/components/Home.jsx
import React, { useState, useEffect } from 'react';

import chatBackgroundImage from '../../assets/images/dashboard.jpg';
import axios from 'axios';

export default function Dashboard() {
 

  
  // Funtion to handle the fetching of subjects
  
  return (
    <div style={{ height: '104%' }} className='flex flex-row flex-grow'>
      <div class="bg-white flex-grow ml-4 mr-4 bg-white items-left justify-left rounded-md max-h-screen">
        {/* Title and Subtitle */}
        <div className='flex flex-row gap-4'>
        <div>
            <h1 class="pt-2 pl-4 text-2xl font-extrabold text-left text-black">My Applications</h1>
            <p className="text-sm pl-4 text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
            View the status of all your job applications here.
            </p>
        </div>
        <form className='flex flex-row gap-4 items-center justify-center mr-4 ml-auto mt-4'>
            <input className='bg-slate-200 p-1 pl-3 h-10 rounded-md text-xs font-semibold focus:outline-none' placeholder='Enter Application Code'></input>
        </form>
        </div>
        <div className='flex flex-col' style={{ width: '100%', height: '90%' }}>

        {/* First Row - Statistic Cards */}
        <div className='flex-grow rounded-md ml-4 mt-4 mr-4 h-72 bg-gray-200 overflow-hidden'>
        <img
          src={chatBackgroundImage}
          alt="Chat Background"
          className='w-full h-full object-cover'
        />
      </div>

        {/* Second Row - Other Cards */}
        <div className='flex flex-row mt-4 mb-4' style={{ width: '100%', height: '100%' }}>
          {/* 1st Card */}
          <div style={{ width: '100%' }}  className="flex-col ml-4 mr-4 rounded-md">
            <table style={{ width: '61%', height: '70%' }}  class="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-lg overflow-hidden">
                <thead class="bg-slate-200">
                    <tr>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Job Type</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interview Date</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">001</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Company A</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Onsite</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Product Manager</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Chennai</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">20th May 2024</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap"> <span className='p-1 bg-green-400 text-white rounded-md'>Accepted</span></td>
                        <td className='flex flex-row gap-2 mt-3 ml-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 hover:text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 hover:text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">002</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Company B</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Hybrid</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Product Manager</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Bangalore</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">20th May 2024</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap"> <span className='p-1 bg-blue-400 text-white rounded-md'>Pending</span></td>
                        <td className='flex flex-row gap-2 mt-3 ml-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 hover:text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 hover:text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">003</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Company C</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Remote</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Product Manager</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap">Chennai</td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap"> <span className='p-1 pl-2 pr-2 bg-orange-300 text-white rounded-md'>To Be Confirmed</span></td>
                        <td class="px-6 py-2 text-xs font-semibold whitespace-nowrap"> <span className='p-1 bg-red-400 text-white rounded-md'>Rejected</span></td>
                        <td className='flex flex-row gap-2 mt-3 ml-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 hover:text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 hover:text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </td>
                    </tr>
                </tbody>
            </table>
           
            
          </div>

          

        </div>

        </div>
     

      </div>
    </div>

      

     

      
   
  );
}

