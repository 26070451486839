import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import "./App.css";

import AdminRoute from "./AdminRoute";
import SuperAdminRoute from "./SuperAdminRoute";
import EmployerRoute from "./EmployerRoute";

import UserLoader from "./UserLoader";

import Layout from "./components/Layout";
import MainLayout from "./components/MainLayout";
import AdminLayout from "./components/Admin_MainLayout";
import ManageLayout from "./components/ManagerLayout";

import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/candidate/Dashboard";
import Application from "./pages/candidate/Applications";
import Topics from "./pages/candidate/Topics";

import Setting from "./pages/candidate/Setting";
import Experience from "./pages/candidate/Experience";
import Certifications from "./pages/candidate/Certifications";

import Chat from "./pages/candidate/Chat";
import Q1 from "./pages/candidate/Q1";
import Q2 from "./pages/candidate/Q2";
import Revision from "./pages/candidate/Revision";

import ApplyAdmin from "./pages/ApplyAdmin";

// Admin Portal
import AdminDashboard from "./pages/admin/dashboard";
import ManageSimulator from "./pages/admin/manage-simulator";
import AdminFeedback from "./pages/admin/feedback";
import AdminApplication from "./pages/admin/adminapplications";
import ManageOverview from "./pages/admin/manage-overview";

// Employer Portal
import Comingsoon from "./pages/employer/comingsoon";
import EmployerLayout from "./components/Employer_MainLayout";

// Test Pages
import Test from "./pages/candidate/Test";
import Test2 from "./pages/candidate/Test2";
import Test3 from "./pages/candidate/Test3";

import { CUserProvider } from "./contexts/Cusercontext";
import { CTopicProvider } from "./contexts/Ctopiccontext";

//

function App() {
  return (
    <CUserProvider>
      <CTopicProvider>
        <Router>
          <div>
            <Routes>
              // Test Pages
              <Route
                path="/test"
                element={
                  <MainLayout>
                    <Test />
                  </MainLayout>
                }
              />
              <Route
                path="/test2"
                element={
                  <MainLayout>
                    <Test2 />
                  </MainLayout>
                }
              />
              <Route
                path="/test3"
                element={
                  <MainLayout>
                    <Test3 />
                  </MainLayout>
                }
              />
              // Start Pages
              <Route
                path="/"
                element={
                  <Layout>
                    <Home />
                  </Layout>
                }
              />
              <Route
                path="/sign-in"
                element={
                  <Layout>
                    <SignIn />
                  </Layout>
                }
              />
              <Route
                path="/sign-up"
                element={
                  <Layout>
                    <SignUp />
                  </Layout>
                }
              />
              <Route
                path="/apply-admin"
                element={
                  <Layout>
                    <ApplyAdmin />
                  </Layout>
                }
              />
              // Candidate Portal
              <Route
                path="/dashboard"
                element={
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                }
              />
             
              <Route
                path="/application"
                element={
                  <MainLayout>
                    <Application />
                  </MainLayout>
                }
              />
              <Route
                path="/topics"
                element={
                  <MainLayout>
                    <Topics />
                  </MainLayout>
                }
              />
              <Route
                path="/settings"
                element={
                  <MainLayout>
                    <Setting />
                  </MainLayout>
                }
              />
              <Route
                path="/experience"
                element={
                  <MainLayout>
                    <Experience />
                  </MainLayout>
                }
              />
              <Route
                path="/certifications"
                element={
                  <MainLayout>
                    <Certifications />
                  </MainLayout>
                }
              />
              <Route
                path="/chat"
                element={
                  <MainLayout>
                    <Chat />
                  </MainLayout>
                }
              />
              <Route
                path="/Q1"
                element={
                  <MainLayout>
                    <Q1 />
                  </MainLayout>
                }
              />
              <Route
                path="/Q2"
                element={
                  <MainLayout>
                    <Q2 />
                  </MainLayout>
                }
              />
              <Route
                path="/revision"
                element={
                  <MainLayout>
                    <Revision />
                  </MainLayout>
                }
              />
              // Admin Portal
              <Route
                path="/admin-dashboard"
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <AdminDashboard />
                    </AdminLayout>
                  </AdminRoute>
                }
              />
              <Route
                path="/manage-simulator"
                element={
                  <AdminRoute>
                    <SuperAdminRoute>
                      <AdminLayout>
                        <ManageLayout>
                          <ManageSimulator />
                        </ManageLayout>
                      </AdminLayout>
                    </SuperAdminRoute>
                  </AdminRoute>
                }
              />
              <Route
                path="/manage-overview"
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <ManageLayout>
                        <ManageOverview />
                      </ManageLayout>
                    </AdminLayout>
                  </AdminRoute>
                }
              />
              <Route
                path="/manage-feedback"
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <AdminFeedback />
                    </AdminLayout>
                  </AdminRoute>
                }
              />
              <Route
                path="/manage-admin-application"
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <AdminApplication />
                    </AdminLayout>
                  </AdminRoute>
                }
              />
              // Employer Portal
              <Route
                path="/employer-dashboard"
                element={
                  <EmployerRoute>
                    <EmployerLayout>
                      <Comingsoon />
                    </EmployerLayout>
                  </EmployerRoute>
                }
              />
              <Route path="/user-loader" element={<UserLoader />} />
            </Routes>
          </div>
        </Router>
      </CTopicProvider>
    </CUserProvider>
  );
}

export default App;
