import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import { useCUser } from "../contexts/Cusercontext";

export default function Home() {
  const [data, setData] = useState(null);
  const { isDarkMode } = useCUser();

  useEffect(() => {
    axios
      .get("http://127.0.0.1:5000/api/data")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <div className="container mx-auto p-4">
      <main className="flex flex-col flex-1 justify-center">
        {/* Blank Space on top to prevent collision with header */}
        <div className="lg:mt-0 mt-8 xl:mt-8 w-full rounded-md sm:h-4 lg:h-36 xl:h-12"></div>

        {/* Initial Introductory Banner */}
        <div className="text-center mb-16 mt-20 md:pb-16">
          <h1 className="text-8xl md:text-7xl font-medium leading-tighter tracking-tighter mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
              The Future of Interviews is Here.
            </span>
          </h1>
          <div className="max-w-3xl mx-auto">
            <p className="text-3xl text-gray-600 dark:text-gray-400 mb-8">
              Revolutionize Your Interview Preparation with AVA - Your AI
              Interview Assistant
            </p>
          </div>
        </div>

        {/* Section header */}
        <div className="max-w-7xl mx-auto mt-12 text-center pb-12 md:pb-2 px-4">
          <h1 className="text-2xl text-gray-800 font-bold mb-2 dark:text-gray-200">
            Our Features
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400 text-justify">
            Some of our most asked questions compiled just for you.
          </p>
        </div>

        {/* Features Section */}
        <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row mx-auto mt-8 space-y-8 md:space-y-0 md:space-x-8 mb-8 px-4">
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 border dark:border-gray-400 rounded-xl p-8 flex flex-col items-center text-justify">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Personalize your sessions
            </h1>
            <h2 className="text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 border dark:border-gray-400 rounded-xl p-8 flex flex-col items-center text-center">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Practice interview questions
            </h1>
            <h2 className="text-justify text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
        </div>

        {/* Ecosystem Section */}
        <div className="max-w-7xl mx-auto mt-12 text-center pb-12 md:pb-2 px-4">
          <h1 className="text-2xl text-gray-800 dark:text-gray-200 font-bold mb-2">
            Explore the AVA Ecosystem
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400 text-justify">
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur excepteur sint occaecat
            cupidatat.
          </p>
        </div>
        <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row mx-auto mt-8 space-y-8 md:space-y-0 md:space-x-8 px-4">
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 rounded-xl p-8 flex flex-col items-center text-center">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Candidate Portal
            </h1>
            <h2 className="text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4 text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 rounded-xl p-8 flex flex-col items-center text-center">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Employer Portal
            </h1>
            <h2 className="text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 rounded-xl p-8 flex flex-col items-center text-center">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Admin Portal
            </h1>
            <h2 className="text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="max-w-7xl mx-auto mt-12 text-center pb-12 md:pb-2 px-4">
          <h1 className="text-2xl text-gray-800 dark:text-gray-200 font-bold mb-2">
            Frequently Asked Questions
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Some of our most asked questions compiled just for you.
          </p>
        </div>
        <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row mx-auto mt-8 space-y-8 md:space-y-0 md:space-x-8 px-4 mb-8">
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 rounded-xl p-8 flex flex-col items-start text-start">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Sample Question 1?
            </h1>
            <h2 className="text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
          <div className="flex-1 bg-gray-100 dark:bg-gray-700 rounded-xl p-8 flex flex-col items-start text-start">
            <h1 className="text-2xl text-gray-800 dark:text-white font-bold mb-2">
              Sample Question 2
            </h1>
            <h2 className="text-lg mt-4 font-semibold text-gray-600 dark:text-gray-300 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </h2>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
