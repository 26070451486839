import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCUser } from "../../contexts/Cusercontext";
import axios from "axios";

function Newnav() {
  const [top, setTop] = useState(true);
  const { firstname, userId, usertype, menu, setUser, setMenu, setFirst } = useCUser();
  const navigate = useNavigate();
  const [toggleAudio, setToggleAudio] = useState(true);
  const [toggleDisplay, setToggleDisplay] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  console.log(userId);

  const handleMouseEnter = () => {
    setIsDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false);
  };

  const handleMenuSelection = (selectedMenu) => {
    setMenu(selectedMenu);
    navigate("/" + selectedMenu);
  };

  const handleAudioToggle = () => {
    setToggleAudio(!toggleAudio);
  };

  const handledisplayToggle = () => {
    setToggleDisplay(!toggleDisplay);
  };

  const handleHamburgerToggle = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  const setUserOffline = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/setstatus/offline`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ linkedin_id: userId }), // Assuming userId is the LinkedIn ID
      });

      if (!response.ok) {
        console.error('Failed to set status offline');
      } else {
        console.log('set Status as offline')
      }
    } catch (error) {
      console.error('Error setting status offline:', error);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const Logout = async () => {
    try {
      const response = await axios.post("http://localhost:5000/api/logout");
      setUserOffline();
      setMenu(null);
      setFirst(null);
      setUser("Guest");
      
      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
        
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div
      className={`fixed w-full bg-white z-0 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white backdrop-blur-sm shadow-lg"
      } ${isDarkMode ? "dark" : ""}`}
    >
      <div className="hidden lg:block max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="flex items-center h-16">
          <div className="flex-shrink-0  mr-4 md:ml-8 text-light text-xl dark:text-white">
            <span className="ml-4">A</span>
            <span className="text-slate-500">V</span>
            <span>A</span>
          </div>
          <nav className="flex flex-grow items-center">
            <ul className="flex flex-grow items-center gap-4">
              <button
                onClick={() => handleMenuSelection("admin-dashboard")}
                className={`lg:text-sm font-semibold hidden sm:inline p-2 transition duration-150 ${
                  menu === "admin-dashboard"
                    ? "text-stone-500 border-b-2"
                    : "hover:text-stone-500 hover:border-b-2"
                }`}
              >
                Dashboard
              </button>
              {usertype === "superadmin" && (
                <button
                  onClick={() => handleMenuSelection("manage-simulator")}
                  className={`lg:text-sm font-semibold hidden sm:inline p-2 transition duration-150 ${
                    menu === "manage-simulator"
                      ? "text-stone-500 border-b-2"
                      : "hover:text-stone-500 hover:border-b-2"
                  }`}
                >
                  Manage Simulator
                </button>
              )}
              <button
                onClick={() => handleMenuSelection("manage-feedback")}
                className={`lg:text-sm font-semibold hidden sm:inline p-2 transition duration-150 ${
                  menu === "manage-feedback"
                    ? "text-stone-500 border-b-2"
                    : "hover:text-stone-500 hover:border-b-2"
                }`}
              >
                Manage Feedback
              </button>
              {usertype === "superadmin" && (
                <button
                  onClick={() =>
                    handleMenuSelection("manage-admin-application")
                  }
                  className={`lg:text-sm font-semibold hidden sm:inline p-2 transition duration-150 ${
                    menu === "manage-admin-application"
                      ? "text-stone-500 border-b-2"
                      : "hover:text-stone-500 hover:border-b-2"
                  }`}
                >
                  Admin Applications
                </button>
              )}
              <div className="flex flex-row gap-2 ml-auto mr-10">
                {usertype === "superadmin" && (
                  <div className="text-sm mt-0 font-semibold ml-auto cursor-pointer dark:text-gray-300 mt-3">
                    Super Admin, {userId}
                  </div>
                )}
                {usertype === "admin" && (
                  <div className="flex flex-row gap-2 font-semibold pt-2 text-sm text-stone-900 mt-2 bg-stone-200 rounded-md pr-2 pl-2 pb-2">
                    Admin
                  </div>
                )}
                {usertype === "readonlyadmin" && (
                  <div className="flex flex-row gap-2 font-semibold pt-2 text-sm text-stone-900 mt-2 bg-stone-200 rounded-md pr-2 pl-2 pb-2">
                    Read Only Admin
                  </div>
                )}
                <li
                  className="relative inline-block mt-1 p-2 rounded-md text-left ml-auto mr-2"
                  onMouseEnter={handleMouseEnter}
                >
                  <div className="text-sm mt-0 font-semibold ml-auto cursor-pointer dark:text-gray-300">
                    {firstname ? `Welcome, ${firstname}!` : "Welcome, Guest!"}
                    <div
                      className={`absolute left-0 mt-2 w-36 bg-white border border-gray-200 rounded-md shadow-lg transition-opacity duration-200 ${
                        isDropdownVisible
                          ? "opacity-100 visible"
                          : "opacity-0 invisible"
                      }`}
                      onMouseLeave={handleMouseLeave}
                    >
                      <ul>
                        <li
                          onClick={() => handleMenuSelection("test")}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          Test
                        </li>
                        <li
                          onClick={() => handleMenuSelection("dashboard")}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          View Candidate
                        </li>
                        <li
                          onClick={() =>
                            handleMenuSelection("employer-dashboard")
                          }
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          View Employer
                        </li>
                        <li
                          onClick={Logout}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer hover:text-red-400"
                        >
                          Log Out
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 z-40 bg-gray-800 bg-opacity-75 transition-opacity duration-300 ${
          isHamburgerMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
        } lg:hidden`}
        onClick={handleHamburgerToggle}
      >
        <div className="fixed top-0 left-0 h-full w-64 bg-white dark:bg-gray-800 p-4 shadow-lg">
          <button
            onClick={handleHamburgerToggle}
            className="p-2 text-gray-600 dark:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="flex flex-col mt-4 h-full">
            <li className="flex-shrink-0 mb-4 text-light text-xl dark:text-white">
              <span className="ml-2">A</span>
              <span className="text-slate-500">V</span>
              <span>A</span>
            </li>
            <li className="py-2">
              <button
                onClick={() => handleMenuSelection("admin-dashboard")}
                className={`rounded-md block w-full text-left p-2 ${
                  menu === "admin-dashboard"
                    ? "bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-100"
                    : "text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                }`}
              >
                Dashboard
              </button>
            </li>
            {usertype === "superadmin" && (
              <li className="py-2">
                <button
                  onClick={() => handleMenuSelection("manage-simulator")}
                  className={`rounded-md block w-full text-left p-2 ${
                    menu === "manage-simulator"
                      ? "bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-100"
                      : "text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                  }`}
                >
                  Manage Simulator
                </button>
              </li>
            )}
            <li className="py-2">
              <button
                onClick={() => handleMenuSelection("manage-feedback")}
                className={`rounded-md block w-full text-left p-2 ${
                  menu === "manage-feedback"
                    ? "bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-100"
                    : "text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                }`}
              >
                Manage Feedback
              </button>
            </li>
            {usertype === "superadmin" && (
              <li className="py-2">
                <button
                  onClick={() =>
                    handleMenuSelection("manage-admin-application")
                  }
                  className={`rounded-md block w-full text-left p-2 ${
                    menu === "manage-admin-application"
                      ? "bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-100"
                      : "text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                  }`}
                >
                  Admin Applications
                </button>
              </li>
            )}
            <l1 className="py-2">
              <button
                onClick={() => handleMenuSelection("dashboard")}
                className={`rounded-md block w-full text-left p-2 ${
                  menu === "manage-feedback"
                    ? "bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-100"
                    : "text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                }`}
              >
                Candidate View
              </button>
            </l1>
            <li className="py-2">
              <button
                onClick={Logout}
                className="w-full text-left p-2 text-red-500 hover:bg-gray-200 dark:hover:bg-gray-600"
              >
                Log Out
              </button>
            </li>
            <div className="mt-auto mb-2 px-2 text-gray-900 dark:text-gray-100">
              {firstname ? `Welcome, ${firstname}!` : "Welcome, Guest!"}
            </div>
            {usertype === "superadmin" && (
              <div className="py-2 pl-2 text-gray-900 dark:text-gray-100">
                Super Admin
              </div>
            )}

            {usertype === "admin" && (
              <div className="py-2 pl-2  text-gray-900 dark:text-gray-100">
                Admin
              </div>
            )}
            {usertype === "readonlyadmin" && (
              <div className="py-2 pl-2  text-gray-900 dark:text-gray-100">
                Read Only Admin
              </div>
            )}

            <div className="flex flex-row gap-2 text-gray-900 dark:text-gray-100 font-semibold mb-14 rounded-md p-2">
              500 TKNS
            </div>
          </ul>
        </div>
      </div>

      {/* Fixed Toggle Button */}
      <button
        className="lg:hidden fixed bottom-20 right-4 p-3 bg-gray-500 text-white rounded-full shadow-lg hover:bg-gray-600 transition-colors duration-300"
        onClick={handleHamburgerToggle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 6h15M4.5 12h15M4.5 18h15"
          />
        </svg>
      </button>

      {/* Dark Mode Toggle Button */}
      <button
        onClick={toggleDarkMode}
        className="lg:hidden fixed bottom-4 shadow-md right-4 flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700 rounded-full shadow transition duration-150 ease-in-out p-3 hover:cursor-pointer"
        aria-label="Toggle Dark Mode"
      >
        {isDarkMode ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-7 fill-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-7 fill-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
            />
          </svg>
        )}
      </button>
    </div>
  );
}

export default Newnav;
