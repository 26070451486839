import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCTopic } from "../../contexts/Ctopiccontext";
import { Alert, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

function ManageSimulator() {
  const { Topics, setTop, topic } = useCTopic();
  const [oldname, setOldname] = useState("");
  const [newname, setNewname] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [managingSubtype, setmanagingSubtype] = useState(false);
  const [managingPrompts, setmanagingPrompts] = useState(false);
  const [managingAdd, setmanagingAdd] = useState(false);
  const [deleteSubtopic, setdeleteSubtopic] = useState(false);
  const [deleteSubtype, setdeleteSubtype] = useState(false);
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedSubtype, setSelectedSubtype] = useState("");
  const [subtopics, setSubtopics] = useState([]);
  const [newSubtopic, setNewSubtopic] = useState("");
  const [newSubtype, setNewSubtype] = useState("");
  const [subtypes, setSubtypes] = useState([]);

  const [currentSubtopic, setcurrentSubtopic] = useState("");
  const [currentSubtype, setcurrentSubtype] = useState("");

  const [promptType, setpromptType] = useState("");
  const [editingpromptType, seteditingpromptType] = useState("");

  const [totalSubtypes, setTotalSubtypes] = useState(0);
  const [totalSampleQuestions, setTotalSampleQuestions] = useState(0);

  const [nameStatus, setnameStatus] = useState("");
  const [nameValue, setNameValue] = useState("");

  const [errorMsg, seterrorMsg] = useState("");
  const [errorMsgOpen, seterrorMsgOpen] = useState(false);

  const [successMsg, setsuccessMsg] = useState("");
  const [successMsgOpen, setsuccessMsgOpen] = useState(false);

  const [availabilityStatus, setAvailabilityStatus] = useState("");
  const [error, setError] = useState("");

  // States for subtype prompts for adding and editing
  const [customPrompt, setCustomPrompt] = useState("");
  const [editingcustomPrompt, seteditingCustomPrompt] = useState("");

  // State for subtype name for editing
  const [editingName, seteditingName] = useState("");

  // Handle change event for the textarea
  const handleTextareaChange = (event) => {
    setCustomPrompt(event.target.value);
  };

  const [editable, setEditable] = useState(false);

  const handleNameChange = (e) => {
    setNameValue(e.target.value);
    if (e.target.value.trim() !== "") {
      setnameStatus("Complete");
    } else {
      setnameStatus("Incomplete");
    }
  };

  useEffect(() => {
    // Function to check subtype name availability
    const checkSubtypeAvailability = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/checksubtypeavailability`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              topic_name: topic,
              subtopic_name: selectedSubtopic,
              subtype_name: nameValue,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          // Check if subtype is available or not
          if (data.available) {
            setAvailabilityStatus("Available");
            setError("");
          } else {
            setAvailabilityStatus("Unavailable");
            setError("");
          }
        } else {
          // Handle server error
          setError("Error checking subtype availability");
        }
      } catch (error) {
        console.error("Error checking subtype availability:", error);
        setError("Error checking subtype availability");
      }
    };

    // Check availability only if nameValue is not empty
    if (nameValue.trim() !== "") {
      checkSubtypeAvailability();
    } else {
      setAvailabilityStatus("");
      setError("");
    }
  }, [nameValue]);

  // Function to check availability of Name for Subtype Editing
  useEffect(() => {
    // Function to check subtype name availability
    const checkSubtypeAvailability = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/checksubtypeavailability`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              topic_name: topic,
              subtopic_name: selectedSubtopic,
              subtype_name: editingName,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          // Check if subtype is available or not
          if (data.available) {
            setAvailabilityStatus("Available");
            setError("");
          } else if (editingName === selectedSubtype) {
            setAvailabilityStatus("Current Name");
            setError("");
          } else {
            setAvailabilityStatus("Unavailable");
            setError("");
          }
        } else {
          // Handle server error
          setError("Error checking subtype availability");
        }
      } catch (error) {
        console.error("Error checking subtype availability:", error);
        setError("Error checking subtype availability");
      }
    };

    // Check availability only if nameValue is not empty
    if (editingName.trim() !== "") {
      checkSubtypeAvailability();
    } else {
      setAvailabilityStatus("");
      setError("");
    }
  }, [editingName]);

  const [sampleQuestions, setSampleQuestions] = useState([
    { question: "", company: "" },
  ]);
  const [completedCount, setCompletedCount] = useState(0);
  const [completed, setCompleted] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...sampleQuestions];
    updatedQuestions[index].question = value;
    setSampleQuestions(updatedQuestions);
    updateCompletedCount(updatedQuestions);
  };

  const handleCompanyChange = (index, value) => {
    const updatedQuestions = [...sampleQuestions];
    updatedQuestions[index].company = value;
    setSampleQuestions(updatedQuestions);
    updateCompletedCount(updatedQuestions);
  };

  const addSampleQuestion = () => {
    setSampleQuestions([...sampleQuestions, { question: "", company: "" }]);
  };

  // Function to remove a sample question pair by index
  const removeSampleQuestion = (indexToRemove) => {
    const updatedQuestions = sampleQuestions.filter(
      (_, index) => index !== indexToRemove
    );
    setSampleQuestions(updatedQuestions);
    updateCompletedCount(updatedQuestions);
  };

  const updateCompletedCount = (questions) => {
    const count = questions.filter(
      (q) => q.question.trim() !== "" && q.company.trim() !== ""
    ).length;
    setCompletedCount(count);

    if (count >= 5) {
      setCompleted(true); // Set completed to true when count is 5
    } else {
      setCompleted(false); // Ensure completed is false if count is not 5
    }
  };

  useEffect(() => {}, [promptType]);

  const fetchTotalSubtypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/totalsubtypes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ topic_name: topic }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch total subtypes");
      }

      const data = await response.json();
      setTotalSubtypes(data.total_subtypes);
    } catch (error) {
      console.error("Error fetching total subtypes:", error.message);
    }
  };

  const fetchTotalSampleQuestions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/totalsamplequestions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ topic_name: topic }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch total sample questions");
      }

      const data = await response.json();
      setTotalSampleQuestions(data.total_sample_questions);
    } catch (error) {
      console.error("Error fetching total sample questions:", error.message);
    }
  };

  // Function to fetch subtopics for a selected topic
  const fetchSubtopics = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/retrievesubtopics`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ topic_name: topic }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch subtopics");
      }

      const data = await response.json();
      const { subtopics } = data;
      setSubtopics(subtopics); // Set state with fetched subtopics array
    } catch (error) {
      console.error("Error fetching subtopics:", error);
      // Handle error, show user feedback, etc.
    }
  };

  // Fetch subtopics whenever selectedTopic changes
  useEffect(() => {
    if (topic) {
      fetchSubtopics();
      fetchTotalSubtypes();
      fetchTotalSampleQuestions();
    }
  }, [topic]);

  // Function to add a new subtopic to the selected topic
  const handleAddSubtopic = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/addsubtopic`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic_name: topic,
            subtopic_name: newSubtopic,
          }),
        }
      );

      const responseData = await response.json();

      if (response.status === 401) {
        seterrorMsg("Subtopic name already exists for this topic");
        seterrorMsgOpen(true);
      }

      if (!response.ok) {
        console.error("Error:", responseData.error);
        throw new Error(responseData.error);
      }

      // If response is OK, proceed with success actions
      console.log("Success:", responseData.message);
      fetchSubtopics();
      setsuccessMsg("Subtopic Sucessfully Added");
      setsuccessMsgOpen(true);
      setNewSubtopic("");
      // Clear input field after successful addition
    } catch (error) {
      console.error("Error adding subtopic:", error.message);
      // Handle error, show user feedback, etc.
    }
  };

  const handleEditSubtopic = (subtopic) => {
    setIsEditing(true);
    setOldname(subtopic);
  };

  // Function to trigger the opening of the Delete Subtopic Modal
  const triggerDeleteSubtopic = async (subtopicName) => {
    setdeleteSubtopic(true);
    setcurrentSubtopic(subtopicName);
  };

  // Function to delete a subtopic from the selected topic
  const handleDeleteSubtopic = async (subtopicName) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deletesubtopic`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subtopic_name: subtopicName,
            topic_name: topic,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete subtopic");
      }

      // Refresh subtopics list after deleting subtopic

      console.log(currentSubtopic);
      fetchSubtopics();
      setdeleteSubtopic(false);
      setsuccessMsg("Subtopic Sucessfully Deleted");
      setsuccessMsgOpen(true);
      setcurrentSubtopic("");
    } catch (error) {
      console.error("Error deleting subtopic:", error);
      // Handle error, show user feedback, etc.
    }
  };

  // Function to toggle managingPrompts state
  const toggledeleteSubtopic = () => {
    setdeleteSubtopic(!deleteSubtopic);
    setcurrentSubtopic("");
  };

  // Function to handle input change and update state
  const handleInputChange = (e) => {
    setNewname(e.target.value); // Update newName state with input value
  };

  // Function to handle update subtopic name editing
  const updateSubtopic = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/updatesubtopic`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            oldName: oldname,
            newName: newname,
            topicName: topic, // Pass selected topic to backend
          }),
        }
      );

      const responseData = await response.json();

      if (response.status === 401) {
        seterrorMsg("Subtopic name already exists for this topic");
        seterrorMsgOpen(true);
      }

      if (!response.ok) {
        throw new Error("Failed to update subtopic");
      }

      if (response.status === 401) {
        seterrorMsg("Subtopic name already exists for this topic");
        seterrorMsgOpen(true);
      }

      // Handle success
      setIsEditing(false);
      fetchSubtopics();
      alert("Subtopic updated successfully!");
    } catch (error) {
      console.error("Error updating subtopic:", error);
      // Handle error, show user feedback, etc.
    }
  };

  // Function to fetch subtypes
  async function fetchSubtypes() {
    if (selectedSubtopic !== "") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/retrievesubtypes`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              topic_name: topic,
              subtopic_name: selectedSubtopic,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch subtypes");
        }

        const data = await response.json();
        const { subtypes } = data;
        console.log(subtypes);
        setSubtypes(subtypes); // Assuming subtypes is an array of subtype names
      } catch (error) {
        console.error("Error fetching subtypes:", error);
        setSubtypes([]);
        // Handle error, show user feedback, etc.
      }
    }
  }

  const handleAddSubtype = async () => {
    const data = {
      topic: topic, // Replace with actual topic name
      subtopic: selectedSubtopic, // Replace with actual subtopic name
      subtype_name: nameValue,
      rubric_prompt_type: promptType,
      ...(promptType === "Custom" && { rubric_prompt: customPrompt }),
      sample_questions: sampleQuestions.filter(
        (sq) => sq.question && sq.company
      ), // Filter out empty questions
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/createsubtype`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create subtype");
      }

      // Optionally handle success response or close modal
      toggleManagingAdd(); // Close the modal after successful subtype creation
      setError(""); // Clear any error messages
      setAvailabilityStatus(""); // Reset availability status
      setNameValue(""); // Clear name value
      setSampleQuestions([{ question: "", company: "" }]); // Reset sample questions to initial state
      setpromptType("");
      setsuccessMsg("Subtype Sucessfully Added");
      setsuccessMsgOpen(true);
      fetchSubtypes();
      fetchTotalSubtypes();
      fetchTotalSampleQuestions();
      setCurrentStep(1);
      setCompletedCount(0);
    } catch (error) {
      console.error("Error creating subtype:", error);
      // Handle error, show user feedback, etc.
    }
  };

  // Function to trigger the opening of the Delete Subtopic Modal
  const triggerDeleteSubtype = async (subtopicName) => {
    setdeleteSubtype(true);
    setcurrentSubtype(subtopicName);
  };

  // Function to toggle managingPrompts state
  const toggledeleteSubtype = () => {
    setdeleteSubtype(!deleteSubtype);
    setcurrentSubtype("");
  };

  // Function to delete a subtype
  const handleDeleteSubtype = async (subtypeName) => {
    try {
      console.log(topic);
      console.log(selectedSubtopic);
      console.log(subtypeName);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deletesubtype`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic: topic,
            subtopic: selectedSubtopic,
            subtype_name: subtypeName,
          }),
        }
      );
      fetchSubtypes();
      fetchTotalSubtypes();
      setdeleteSubtype(false);
      setsuccessMsg("Subtype Sucessfully Deleted");
      setsuccessMsgOpen(true);
      setcurrentSubtype("");
      fetchTotalSampleQuestions();

      if (!response.ok) {
        throw new Error("Failed to delete subtopic");
      }

      // Clear input field after successful addition
    } catch (error) {
      console.error("Error deleting subtype:", error);
      // Handle error, show user feedback, etc.
    }
  };

  // Handle click on subtopic button
  const handleSubtopicClick = (subtopic) => {
    setSelectedSubtopic(subtopic);
    setmanagingSubtype(true);
  };

  const [subtypeData, setSubtypeData] = useState({
    subtype_name: "",
    prompt: "",
    sample_questions: [],
  });

  const addSampleQuestion2 = () => {
    const newQuestion = { question: "", company: "" };
    const updatedSampleQuestions = [
      ...subtypeData.sample_questions,
      newQuestion,
    ];
    setSubtypeData({
      ...subtypeData,
      sample_questions: updatedSampleQuestions,
    });
  };

  // Function to remove a sample question pair by index
  const removeSampleQuestion2 = (indexToRemove) => {
    const updatedQuestions = subtypeData.sample_questions.filter(
      (_, index) => index !== indexToRemove
    );
    setSubtypeData({
      ...subtypeData,
      sample_questions: updatedQuestions,
    });
    // Additional logic if needed
  };

  // Function to handle question change by index
  const handleQuestionChange2 = (index, value) => {
    const updatedQuestions = [...subtypeData.sample_questions];
    updatedQuestions[index].question = value;
    setSubtypeData({
      ...subtypeData,
      sample_questions: updatedQuestions,
    });
    // Call additional logic if needed
  };

  // Function to handle company change by index
  const handleCompanyChange2 = (index, value) => {
    const updatedQuestions = [...subtypeData.sample_questions];
    updatedQuestions[index].company = value;
    setSubtypeData({
      ...subtypeData,
      sample_questions: updatedQuestions,
    });
    // Call additional logic if needed
  };

  // Update newname when selectedSubtype changes
  useEffect(() => {
    seteditingName(selectedSubtype);
    seteditingCustomPrompt(subtypeData.rubric_prompt);
  }, [selectedSubtype]);

  // Function to fetch subtype Info
  const fetchSubtypeData = async () => {
    try {
      console.log(currentSubtype);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/get_subtype`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic: topic, // Replace with actual topic name
            subtopic: selectedSubtopic, // Replace with actual subtopic name
            subtype_name: selectedSubtype,
          }),
        }
      );

      console.log("Completed Retreieving Subtype");

      if (!response.ok) {
        throw new Error("Failed to fetch subtype Info");
      }

      const responseData = await response.json(); // Parse response JSON
      console.log(responseData); // Log the parsed data

      // Update subtypeData state
      setSubtypeData({
        subtype_name: responseData.subtype_name,
        rubric_prompt_type: responseData.rubric_prompt_type,
        rubric_prompt: responseData.rubric_prompt,
        sample_questions: responseData.sample_questions,
      });

      seteditingpromptType(responseData.rubric_prompt_type);
      seteditingCustomPrompt(responseData.rubric_prompt);
    } catch (error) {
      console.error("Error fetching subtype Info:", error);
      setSubtypes([]);
      // Handle error, show user feedback, etc.
    }
  };

  // Fetch subtype info whenever selectedSubtype changes
  useEffect(() => {
    if (selectedSubtype) {
      fetchSubtypeData();
    }
  }, [selectedSubtype]);

  // Handle click on subtopic button
  const handleSubtypeClick = (subtype) => {
    setSelectedSubtype(subtype);
    setmanagingPrompts(true);
  };

  // Handle click on subtype Add Button
  const handleSubtypeAdd = async () => {
    setmanagingAdd(true);
  };

  // Function to toggle managingPrompts state
  const toggleManagingPrompts = () => {
    setmanagingPrompts(!managingPrompts);
    setSelectedSubtype("");
  };

  // Function to toggle managingPrompts state
  const toggleManagingAdd = () => {
    setmanagingAdd(!managingAdd); // Toggle managingAdd state
    setError(""); // Clear any error messages
    setAvailabilityStatus(""); // Reset availability status
    setNameValue(""); // Clear name value
    setSampleQuestions([{ question: "", company: "" }]); // Reset sample questions to initial state
    setpromptType("");
    setCurrentStep(1);
    setCompletedCount(0);
  };

  const handleCrumbs = async () => {
    setTop(topic);
    setmanagingSubtype(false);
    setSelectedSubtopic("");
    setIsEditing(false);
  };

  // Function to close the notification for Error messages
  const handleErrorMsgClose = async () => {
    seterrorMsgOpen(false);
    seterrorMsg("");
  };

  // Function to close the notification for Success messages
  const handleSuccessMsgClose = async () => {
    setsuccessMsgOpen(false);
    setsuccessMsg("");
  };

  // Function to fetch subtopics for a selected topic
  useEffect(() => {
    (async () => {
      console.log(topic);
      console.log(selectedSubtopic);
      if (selectedSubtopic !== "") {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/retrievesubtypes`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                topic_name: topic,
                subtopic_name: selectedSubtopic,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch subtypes");
          }

          const data = await response.json();
          const { subtypes } = data;
          console.log(subtypes);
          setSubtypes(subtypes); // Assuming subtypes is an array of subtype names
        } catch (error) {
          console.error("Error fetching subtypes:", error);
          setSubtypes("");
          // Handle error, show user feedback, etc.
        }
      }
    })();
  }, [selectedSubtopic]);

  // Watch for changes in 'topic' and reset sates accordingly
  useEffect(() => {
    setmanagingSubtype(false);
    setSelectedSubtopic("");
    setIsEditing(false);
  }, [topic]);

  // Function to toggle next step in Create Subtype Modal
  const handleNext = () => {
    if (currentStep < 3) {
      // Assuming there are 3 steps in total
      setCurrentStep(currentStep + 1);
    }
  };

  // Function to toggle previous step in Create Subtype Modal
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateSubtype = async () => {
    try {
      console.log("started");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/updatesubtype`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic_name: topic,
            subtopic_name: selectedSubtopic,
            subtype_name: selectedSubtype,
            new_name: editingName,
            rubric_prompt_type: editingpromptType,
            rubric_prompt: editingcustomPrompt,
            sample_questions: subtypeData.sample_questions,
          }),
        }
      );

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        throw new Error(data.error || "Failed to update subtopic");
      }

      // Handle success
      setIsEditing(false);
      fetchSubtopics();
      fetchSubtypes();
      setsuccessMsg("Subtype Sucessfully Updated");
      setsuccessMsgOpen(true);
    } catch (error) {
      console.error("Error updating subtopic:", error.message);
      // Handle error, show user feedback, etc.
    }
  };

  const toggleEditable = async () => {
    if (editable) {
      updateSubtype();
      console.log("called the update function");
    }
    setEditable(!editable); // Toggle edit mode state
  };

  const handleUpdateClick = async () => {
    updateSubtype();
  };

  console.log(subtypeData.sample_questions);
  console.log(subtypeData);

  return (
    <div className=" container mx-auto p-0 dark:bg-gray-800 ">
      {/* Layout for small and medium screens */}
      <div
        style={{ width: "90vw" }}
        className="block lg:hidden p-2 rounded-md dark:bg-gray-800 dark:text-white"
      >
        <div className="rounded-md bg-gray-100 text-center flex flex-col gap-2">
          <h1 className="font-bold text-2xl p-2">{topic}</h1>
        </div>
        <div className="flex flex flex-col lg:flex-row gap-2">
          <div className="flex flex-row gap-2 mt-4">
            <div className="bg-slate-200 flex flex-row rounded-md w-48 h-16 text-center">
              <h1 className="mt-3 ml-4 text-sm font-semibold">
                Total Subtopics
              </h1>
              <h1 className="mt-3 ml-6 text-4xl font-semibold">
                {subtopics.length}
              </h1>
            </div>
            <div className="bg-slate-200 flex flex-row rounded-md w-48 h-16 text-center">
              <h1 className="mt-3 ml-4 text-sm font-semibold">
                Total Subtypes
              </h1>
              <h1 className="mt-3 ml-6 text-4xl font-semibold">
                {totalSubtypes}
              </h1>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="bg-slate-200 flex flex-row rounded-md w-48 h-16 text-center">
              <h1 className="mt-3 ml-4 text-sm font-semibold">Total Samples</h1>
              <h1 className="mt-3 ml-4 text-4xl font-semibold">
                {totalSampleQuestions}
              </h1>
            </div>
            <div className="bg-slate-200 flex flex-row rounded-md w-48 h-16 text-center">
              <h1 className="mt-3 ml-4 text-sm font-semibold">
                Custom Prompts
              </h1>
              <h1 className="mt-3 ml-4 text-4xl font-semibold">0</h1>
            </div>
          </div>
        </div>
        <h1 className="block mt-4 flex flex-row">
          <div className="mr-2">
            <h1
              className="hover:text-slate-500 text-lg font-semibold hover:cursor-pointer"
              onClick={handleCrumbs}
            >
              {topic}
            </h1>
          </div>

          <h1 className=" hidden lg:block text-sm font-semibold">
            {selectedSubtopic && ` > ${selectedSubtopic}`}
          </h1>
        </h1>
        <hr className=" h-0.5 mt-2 rounded-md bg-black dark:bg-gray-200"></hr>
        {/* Display Subtopic Section */}
        <div style={{ width: "100%" }} className="flex flex-col gap-4">
          <div
            style={{
              width: "100%",
              maxHeight: "50vh",
              maxWidth: "550px",
              overflowY: "auto",
            }}
            className="flex flex-col gap-4 pt-4 mt-2"
          >
            {subtopics.length === 0 ? (
              <div className="text-gray-500 text-xl ml-24 mt-16">
                No subtopics available
              </div>
            ) : (
              subtopics.map((subtopic, index) => (
                <div key={index} className="flex items-center">
                  <button
                    style={{ height: "90%", width: "100%" }}
                    className={`border-2 p-2 text-start text-sm font-semibold rounded-md ${
                      selectedSubtopic === subtopic
                        ? "bg-slate-300 border-gray-400"
                        : "bg-slate-200 border-gray-200 hover:border-gray-400"
                    }`}
                    onClick={() => handleSubtopicClick(subtopic)}
                  >
                    {subtopic}
                  </button>
                  <button
                    className="ml-2 p-2 bg-orange-200 hover:bg-orange-300 rounded-md"
                    onClick={() => handleEditSubtopic(subtopic)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </button>
                  <button
                    className="ml-2 p-2 bg-red-200 hover:bg-red-300 rounded-md"
                    onClick={() => triggerDeleteSubtopic(subtopic)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              ))
            )}
          </div>
          {/* Add Subtopic Section */}
          <div className="">
            <input
              type="text"
              value={newSubtopic}
              onChange={(e) => setNewSubtopic(e.target.value)}
              style={{ width: "81%" }}
              className="border-2 p-2 rounded-md text-sm font-semibold focus:outline-none"
              placeholder="Enter New Subtopic"
            />
            <button
              onClick={handleAddSubtopic}
              style={{ width: "15%" }}
              className="ml-2 px-4 py-2 text-sm bg-stone-300 rounded-md"
            >
              Add
            </button>
          </div>
          {/* Modal */}
          {deleteSubtopic && (
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
              <div
                style={{ width: "80%", maxHeight: "75%", overflowY: "auto" }}
                className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg"
              >
                {/* Modal content */}
                <div className="flex flex-row items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-12 text-red-600 mb-4 mr-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                    />
                  </svg>
                  <h2 className="text-sm font-bold mb-4">
                    Are you sure you want to delete "{currentSubtopic}"?
                  </h2>
                  <svg
                    onClick={toggledeleteSubtopic}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-12 ml-auto mb-4 mr-4 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
                <p className="text-gray-700">
                  This will delete all information related to the "
                  {currentSubtopic}" subtopic, including its subtypes, and
                  sample questions. Once deleted, this cannot be undone and the
                  subtopic will no longer be available to the candidates for
                  interview simulations.
                </p>
                <button
                  className="p-2 text-sm font-semibold bg-red-200 rounded-md flex ml-auto mr-4 hover:bg-red-300"
                  onClick={() => handleDeleteSubtopic(currentSubtopic)}
                >
                  Confirm Deletion
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{ height: "95%" }}
        className="hidden lg:flex flex-col lg:pt-2 lg:pl-4 lg:pr-4"
      >
        <div className="hidden lg:flex flex-row">
          <h1 className="font-bold text-left text-2xl">{topic}</h1>
        </div>
        <div className="hidden lg:flex flex flex-col lg:flex-row gap-4">
          <div className="bg-slate-200 flex flex-row rounded-md w-56 h-16 mt-4 text-center">
            <h1 className="mt-3 ml-4 text-sm font-semibold">Total Subtopics</h1>
            <h1 className="mt-3 ml-8 text-4xl font-semibold">
              {subtopics.length}
            </h1>
          </div>
          <div className="bg-slate-200 flex flex-row rounded-md w-56 h-16 mt-4 text-center">
            <h1 className="mt-3 ml-4 text-sm font-semibold">Total Subtypes</h1>
            <h1 className="mt-3 ml-8 text-4xl font-semibold">
              {totalSubtypes}
            </h1>
          </div>
          <div className="bg-slate-200 flex flex-row rounded-md w-56 h-16 mt-4 text-center">
            <h1 className="mt-3 ml-4 text-sm font-semibold">Total Samples</h1>
            <h1 className="mt-3 ml-8 text-4xl font-semibold">
              {totalSampleQuestions}
            </h1>
          </div>
          <div className="bg-slate-200 flex flex-row rounded-md w-56 h-16 mt-4 text-center">
            <h1 className="mt-3 ml-4 text-sm font-semibold">Custom Prompts</h1>
            <h1 className="mt-3 ml-8 text-4xl font-semibold">0</h1>
          </div>
        </div>

        <h1 className="hidden lg:flex mt-4 flex-row items-center mr-2 gap-2">
  <span
    className="hover:text-slate-500 text-sm font-semibold hover:cursor-pointer"
    onClick={handleCrumbs}
  >
    {topic}
  </span>

  {selectedSubtopic && (
    <span className="text-sm font-semibold">
      &gt; {selectedSubtopic}
    </span>
  )}
        </h1>


        <hr className="hidden lg:block h-0.5 mt-1 mr-4 rounded-md bg-black"></hr>
        {!managingSubtype && (
          <>
            <div
              style={{ height: "100%" }}
              className="hidden lg:flex flex-col gap-2"
            >
              {/* Table and Edit Section */}
              <div style={{ height: "100%" }} className="flex flex-row">
                {/* Display Subtopic Section */}
                <div style={{ width: "50%" }} className="flex flex-col gap-4">
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "270px",
                      maxWidth: "550px",
                      overflowY: "auto",
                    }}
                    className="flex flex-col gap-4 pt-4 mt-2"
                  >
                    {subtopics.length === 0 ? (
                      <div className="text-gray-500 text-xl ml-24 mt-16">
                        No subtopics available
                      </div>
                    ) : (
                      subtopics.map((subtopic, index) => (
                        <div key={index} className="flex items-center">
                          <button
                            style={{ height: "90%", width: "80%" }}
                            className={`border-2 p-2 text-start text-sm font-semibold rounded-md ${
                              selectedSubtopic === subtopic
                                ? "bg-slate-300 border-gray-400"
                                : "bg-slate-200 border-gray-200 hover:border-gray-400"
                            }`}
                            onClick={() => handleSubtopicClick(subtopic)}
                          >
                            {subtopic}
                          </button>
                          <button
                            className="ml-2 p-2 bg-orange-200 hover:bg-orange-300 rounded-md"
                            onClick={() => handleEditSubtopic(subtopic)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                          <button
                            className="ml-2 p-2 bg-red-200 hover:bg-red-300 rounded-md"
                            onClick={() => triggerDeleteSubtopic(subtopic)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      ))
                    )}
                  </div>
                  {/* Add Subtopic Section */}
                  <div style={{ height: "10%", width: "100%" }} className="">
                    <input
                      type="text"
                      value={newSubtopic}
                      onChange={(e) => setNewSubtopic(e.target.value)}
                      style={{ width: "81%" }}
                      className="border-2 p-2 rounded-md text-sm font-semibold focus:outline-none"
                      placeholder="Enter New Subtopic"
                    />
                    <button
                      onClick={handleAddSubtopic}
                      style={{ width: "15%" }}
                      className="ml-2 px-4 py-2 text-sm bg-stone-300 rounded-md"
                    >
                      Add
                    </button>
                  </div>
                </div>

                {/* Second Column */}
                {isEditing && (
                  <div
                    style={{ width: "50%" }}
                    className="flex flex-col p-2 mt-4"
                  >
                    <div className="flex flex-row p-2 mb-2 bg-stone-200 text-sm font-medium rounded-md text-gray-500">
                      Editing Subtopic Information
                      <svg
                        onClick={() => setIsEditing(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5 ml-auto mr-2 hover:text-slate-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </div>
                    <label className="text-slate-500 font-semibold">
                      Old Name:
                    </label>
                    <h1 className="bg-slate-200 p-2 focus:outline-none rounded-md mt-2">
                      {oldname}
                    </h1>
                    <label className="text-slate-500 font-semibold mt-4">
                      New Name:
                    </label>
                    <input
                      className="bg-slate-200 p-2 focus:outline-none rounded-md"
                      value={newname}
                      onChange={handleInputChange}
                    />
                    <button
                      className="bg-stone-300 hover:bg-stone-400 rounded-md text-sm p-2 mt-7"
                      onClick={updateSubtopic}
                    >
                      Update Subtopic Name
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {managingSubtype && (
          <>
            {/* Display Subtopic Section */}
            <div style={{ height: "100%" }} className="flex flex-row gap-2">
              <div style={{ width: "50%" }} className="flex flex-col gap-4">
                <div
                  style={{
                    width: "100%",
                    maxHeight: "100vh",
                    maxWidth: "550px",
                    overflowY: "auto",
                  }}
                  className="flex flex-col gap-4 pt-4 mt-2"
                >
                  {subtypes.length === 0 ? (
                    <div className="text-gray-500 text-xl ml-24 mt-16">
                      No subtypes available
                    </div>
                  ) : (
                    subtypes.map((subtype, index) => (
                      <div key={index} className="flex items-center">
                        <h1
                          style={{ width: "80%" }}
                          className={`border-2 p-2 text-sm font-semibold bg-slate-200 rounded-md`}
                        >
                          {subtype}
                        </h1>
                        <button
                          className="ml-2 p-2 bg-orange-200 hover:bg-orange-300 rounded-md"
                          onClick={() => handleSubtypeClick(subtype)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                          </svg>
                        </button>
                        <button
                          className="ml-2 p-2 bg-red-200 hover:bg-red-300 rounded-md"
                          onClick={() => triggerDeleteSubtype(subtype)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    ))
                  )}
                </div>
                {/* Add Subtype Section */}
                <div
                  style={{ height: "10%", width: "100%" }}
                  className=""
                ></div>
              </div>

              <div style={{ width: "48%" }} className="flex flex-col pt-2 mt-4">
                <button
                  onClick={handleSubtypeAdd}
                  className="ml-auto mr-2 w-20 px-4 py-2 text-sm bg-stone-300 rounded-md"
                >
                  Add
                </button>
              </div>
            </div>
          </>
        )}

        {/* Modal */}
        {managingPrompts && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div
              style={{ width: "70%", maxHeight: "75%", overflowY: "auto" }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              <div className="flex flex-row justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 text-amber-600 mb-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
                <h2 className="text-xl ml-4 font-bold mb-4">
                  Managing {selectedSubtype}
                </h2>
                <button
                  onClick={toggleEditable}
                  className={`ml-4 w-24 p-1 mb-4 text-sm rounded-md ${
                    editable ? "bg-green-500 text-white" : "bg-amber-300"
                  }`}
                >
                  {editable ? "Update" : "Edit"}
                </button>
                <svg
                  onClick={toggleManagingPrompts}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 ml-auto mr-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <p className="text-gray-700">
                You must complete both sections in order for the subtype to be
                usable in the candidate portal.
              </p>

              <div>
                <div className="flex flex-row gap-2 align-center">
                  <label className="block text-medium font-medium text-gray-700 mt-4">
                    1) Subtype Name:
                  </label>
                  <input
                    className={`text-sm text-gray-700 p-2 focus:outline-none rounded-md w-48 ml-4 mt-3 ${
                      editable ? "bg-slate-200" : "bg-slate-50"
                    }`}
                    value={editingName} // Use newname state here
                    onChange={(e) => seteditingName(e.target.value)} // Update newname on input change
                    readOnly={!editable} // Make it read-only if not editable
                  />
                  {availabilityStatus && (
                    <span
                      className={
                        availabilityStatus === "Available"
                          ? "text-green-500 ml-4 text-medium font-medium mt-4"
                          : availabilityStatus === "Current Name"
                          ? "text-blue-500 ml-4 text-medium font-medium mt-4"
                          : "text-red-500 ml-2 text-medium font-medium mt-4"
                      }
                    >
                      {availabilityStatus}
                    </span>
                  )}
                </div>
                <label className="block text-medium font-medium text-gray-700 mt-4">
                  2) Sample Questions:
                </label>
                {/* Render sample question inputs dynamically */}

                {subtypeData.sample_questions.map((sample, index) => (
                  <div
                    key={sample.QID}
                    style={{ width: "95%" }}
                    className="mt-2 ml-4 flex flex-row gap-4"
                  >
                    <label className="block text-sm font-medium text-gray-700 mt-2">{`Sample ${
                      index + 1
                    }:`}</label>
                    <input
                      style={{ width: "58%" }}
                      className={`p-2 text-sm rounded-md  focus:outline-none ${
                        editable ? "bg-slate-200" : "bg-slate-50"
                      }`}
                      placeholder="Question"
                      value={sample.question}
                      onChange={(e) =>
                        handleQuestionChange2(index, e.target.value)
                      }
                      readOnly={!editable}
                    />
                    <input
                      className={`p-2 text-sm rounded-md focus:outline-none ${
                        editable ? "bg-slate-200" : "bg-slate-50"
                      }`}
                      placeholder="Company"
                      value={sample.company}
                      onChange={(e) =>
                        handleCompanyChange2(index, e.target.value)
                      }
                      readOnly={!editable}
                    />
                    <button
                      className=" p-2 bg-red-200 hover:bg-red-300 rounded-md"
                      onClick={() => removeSampleQuestion2(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                ))}

                <button
                  className="text-sm mt-4 ml-4 p-2 bg-stone-200 hover:bg-stone-400 rounded-md"
                  onClick={addSampleQuestion2}
                >
                  Add Sample Question
                </button>

                <label className="block text-medium font-medium text-gray-700 mt-4">
                  3) Prompt:
                </label>

                <div className="flex flex-row gap-4">
                  <a
                    className={`flex w-96 ml-4 mt-4 items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      editingpromptType !== "Default"
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      seteditingpromptType("Default");
                    }}
                  >
                    <div>
                      <div className="text-lg font-semibold leading-snug tracking-tight mb-1">
                        Default Rubric Prompt (Not Recommended)
                      </div>
                      <div className="text-sm text-gray-600">
                        This will auto generate a rubric for the selected
                        subtype. Use at your own risk as the rubric may not
                        remain constant on all simulations.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>
                    </div>
                  </a>

                  <a
                    className={`flex w-96 ml-4 mt-4 items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      editingpromptType !== "Custom"
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      seteditingpromptType("Custom");
                    }}
                  >
                    <div>
                      <div className="text-lg font-semibold leading-snug tracking-tight mb-1">
                        Custom Rubric Prompt (Recommended)
                      </div>
                      <div className="text-sm text-gray-600">
                        You can choose to use your own custom prompt to generate
                        a rubric for this subtype. Please note that a more
                        detailed prompt, will provide a more detailed and domain
                        accurate rubric.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
                {editingpromptType == "Custom" && (
                  <>
                    <div>
                      <label className="block text-medium font-medium  text-gray-700 mt-4">
                        3) Custom Prompt:
                      </label>
                      <textarea
                        value={editingcustomPrompt}
                        className={`w-full h-36 mt-4 text-sm focus:outline-none p-2 ${
                          editable ? "bg-slate-200" : "bg-slate-50"
                        }`}
                        readOnly={!editable}
                        onChange={(e) => seteditingCustomPrompt(e.target.value)}
                      ></textarea>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Modal to add a new Subtype */}
        {managingAdd && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div
              style={{
                width: "75%",
                height: "65%",
                maxHeight: "75%",
                overflowY: "auto",
              }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              {/* Modal content */}

              <div className="flex flex-row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 text-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                  />
                </svg>
                <h2 className="text-xl font-bold mb-2 ml-4">
                  Create a new Subtype
                </h2>
                <svg
                  onClick={toggleManagingAdd}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 ml-auto mr-4 hover:cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <p className="text-gray-700">
                You must complete All sections in order for the subtype to be
                created and usable in the candidate portal.
              </p>
              <div>
                <div className="progress-bar">
                  {/* Progress Indicator */}
                  <div className=" flex flex-row gap-4 mt-4 items-center justify-center">
                    <div
                      className={` p-2 rounded-md ${
                        currentStep === 1
                          ? "active bg-slate-300"
                          : "bg-stone-200"
                      }`}
                    >
                      Step 1
                    </div>
                    <div
                      className={` p-2 rounded-md ${
                        currentStep === 2
                          ? "active bg-slate-300"
                          : "bg-stone-200"
                      }`}
                    >
                      Step 2
                    </div>
                    <div
                      className={` p-2 rounded-md ${
                        currentStep === 3
                          ? "active bg-slate-300"
                          : "bg-stone-200"
                      }`}
                    >
                      Step 3
                    </div>

                    {/* Navigation Buttons */}
                    <div className="navigation flex flex-row gap-4 ml-24">
                      {currentStep > 1 && (
                        <button
                          className="bg-stone-200 p-2 rounded-md"
                          onClick={handlePrevious}
                        >
                          Previous
                        </button>
                      )}
                      {currentStep === 1 &&
                        availabilityStatus === "Available" && (
                          <button
                            className="bg-stone-200 p-2 rounded-md"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        )}
                      {currentStep === 2 && completed === true && (
                        <button
                          className="bg-stone-200 p-2 rounded-md"
                          onClick={handleNext}
                        >
                          Next
                        </button>
                      )}
                      {currentStep === 3 && promptType != "" && (
                        <button
                          onClick={handleAddSubtype}
                          className="bg-stone-200 p-2 rounded-md"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Content for each step */}
                  <div className="step-content">
                    {currentStep === 1 && (
                      <div className="flex flex-col mt-6 items-center">
                        {" "}
                        {/* Center content vertically and horizontally */}
                        <h1 className="text-lg font-semibold text-center mt-4">
                          Section 1: Enter the subtype name
                          {availabilityStatus && (
                            <span
                              className={
                                availabilityStatus === "Available"
                                  ? "text-green-500 ml-4"
                                  : "text-red-500 ml-2"
                              }
                            >
                              {availabilityStatus}
                            </span>
                          )}
                        </h1>
                        <input
                          className="p-2 mt-4 mb-4 bg-slate-200 rounded-md focus:outline-none w-96"
                          value={nameValue}
                          onChange={handleNameChange}
                          placeholder="Enter subtype name"
                        />
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div>
                        <label className="flex flex-row text-medium items-center font-medium text-gray-700 mt-2">
                          <h1 className="text-lg text-center font-semibold mt-4">
                            Section 2: Enter Sample Questions{" "}
                          </h1>
                          <span
                            className={`ml-4 mt-4 text-lg font-semibold ${
                              completedCount >= 5
                                ? "text-green-600"
                                : "text-orange-600"
                            }`}
                          >
                            {completedCount}/5
                          </span>
                        </label>

                        {sampleQuestions.map((sample, index) => (
                          <div
                            key={index}
                            style={{ width: "95%" }}
                            className="mt-2 ml-4 flex flex-row gap-4"
                          >
                            <label className="block text-sm font-medium text-gray-700 mt-2">{`Sample ${
                              index + 1
                            }:`}</label>
                            <input
                              style={{ width: "58%" }}
                              className="p-2 text-sm rounded-md focus:outline-none bg-slate-200 "
                              placeholder="Question"
                              value={sample.question}
                              onChange={(e) =>
                                handleQuestionChange(index, e.target.value)
                              }
                            />
                            <input
                              className="p-2 text-sm rounded-md focus:outline-none bg-slate-200 ml-1"
                              placeholder="Company"
                              value={sample.company}
                              onChange={(e) =>
                                handleCompanyChange(index, e.target.value)
                              }
                            />
                            <button
                              className=" p-2 bg-red-200 hover:bg-red-300 rounded-md"
                              onClick={() => removeSampleQuestion(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}

                        <button
                          className="mt-4 ml-4 p-2 bg-blue-500 text-white rounded-md"
                          onClick={addSampleQuestion}
                        >
                          Add Sample Question
                        </button>
                      </div>
                    )}

                    {currentStep === 3 && (
                      <div>
                        <h1 className="text-lg font-semibold mt-4">
                          Section 3: Select Prompt{" "}
                          <span className="text-lg font-semibold text-green-600">
                            {promptType}
                          </span>
                        </h1>
                        <div className="flex flex-row gap-4">
                          <a
                            className={`flex w-96 ml-4 mt-4 items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                              promptType !== "Default"
                                ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                                : "bg-gray-200 border-transparent"
                            }`}
                            href="#0"
                            onClick={(e) => {
                              e.preventDefault();
                              setpromptType("Default");
                            }}
                          >
                            <div>
                              <div className="text-lg font-semibold leading-snug tracking-tight mb-1">
                                Default Prompt
                              </div>
                              <div className="text-sm text-gray-600">
                                This utilizes the default prompt set for AVA
                                that will give the best possible response from
                                the LLM to ensure a seamless interview
                                simulation.
                              </div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                                />
                              </svg>
                            </div>
                          </a>

                          <a
                            className={`flex w-96 ml-4 mt-4 items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                              promptType !== "Custom"
                                ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                                : "bg-gray-200 border-transparent"
                            }`}
                            href="#0"
                            onClick={(e) => {
                              e.preventDefault();
                              setpromptType("Custom");
                            }}
                          >
                            <div>
                              <div className="text-lg font-semibold leading-snug tracking-tight mb-1">
                                Custom Prompt (Coming Soon)
                              </div>
                              <div className="text-sm text-gray-600">
                                You can choose to use your own custom prompt for
                                this subtype, however it is not recommeded as it
                                may affect the performance of the LLM.
                              </div>
                            </div>
                            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {promptType == "Custom" && (
                  <>
                    <div>
                      <label className="block text-medium font-medium text-gray-700 mt-4">
                        3) Custom Prompt:{" "}
                        <span className="text-medium font-normal text-orange-600">
                          Incomplete
                        </span>
                      </label>
                      <textarea
                        value={customPrompt}
                        onChange={handleTextareaChange}
                      ></textarea>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Notification for Success */}
        <Snackbar
          open={successMsgOpen}
          autoHideDuration={3000}
          onClose={handleSuccessMsgClose}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon
                style={{ marginRight: "8px", color: "#4caf50" }}
              />
              {`${successMsg}`}
            </span>
          }
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSuccessMsgClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

        {/* Notification for Errors */}
        <Snackbar
          open={errorMsgOpen}
          autoHideDuration={3000}
          onClose={handleErrorMsgClose}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon style={{ marginRight: "8px", color: "#f44336" }} />
              {`${errorMsg}`}
            </span>
          }
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleErrorMsgClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

        {/* Modal */}
        {deleteSubtopic && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div
              style={{ width: "45%", maxHeight: "75%", overflowY: "auto" }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              {/* Modal content */}
              <div className="flex flex-row items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 text-red-600 mb-4 mr-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                  />
                </svg>
                <h2 className="text-xl font-bold mb-4">
                  Are you sure you want to delete "{currentSubtopic}"?
                </h2>
                <svg
                  onClick={toggledeleteSubtopic}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 ml-auto mb-4 mr-4 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <p className="text-gray-700">
                This will delete all information related to the "
                {currentSubtopic}" subtopic, including its subtypes, and sample
                questions. Once deleted, this cannot be undone and the subtopic
                will no longer be available to the candidates for interview
                simulations.
              </p>
              <button
                className="p-2 text-sm font-semibold bg-red-200 rounded-md flex ml-auto mr-4 hover:bg-red-300"
                onClick={() => handleDeleteSubtopic(currentSubtopic)}
              >
                Confirm Deletion
              </button>
            </div>
          </div>
        )}

        {/* Modal */}
        {deleteSubtype && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div
              style={{ width: "50%", maxHeight: "75%", overflowY: "auto" }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              {/* Modal content */}
              <div className="flex flex-row items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 text-red-600 mb-4 mr-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
                  />
                </svg>
                <h2 className="text-xl font-bold mb-4">
                  Are you sure you want to delete "{currentSubtype}"?
                </h2>
                <svg
                  onClick={toggledeleteSubtype}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-7 ml-auto mb-4 mr-4 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <p className="text-gray-700">
                This will delete all information related to the "
                {currentSubtype}" subtype, including its sample questions. Once
                deleted, this cannot be undone and the subtype will no longer be
                available to the candidates for interview simulations.
              </p>
              <button
                className="p-2 text-sm font-semibold bg-red-200 rounded-md flex ml-auto mr-4 hover:bg-red-300"
                onClick={() => handleDeleteSubtype(currentSubtype)}
              >
                Confirm Deletion
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageSimulator;
