import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCTopic } from "../../contexts/Ctopiccontext";
import positive from "../../assets/audio/Positive1.mp3";

export default function Credentials() {
  const navigate = useNavigate();
  const { Topics, setTop } = useCTopic();
  const [data, setData] = useState(null);
  const [Mytopics, setMytopics] = useState([]);

  function play() {
    new Audio(positive).play();
  }

  // Function to set the topic when a button is clicked
  const handleTopicSelection = (selectedTopic) => {
    setTop(selectedTopic);
    play();
    navigate("/Q1");
  };

  // Memoize Mytopics to cache the result
  const cachedTopics = useMemo(() => Topics, [Topics]);

  return (
    <div className="container ml-auto mr-auto p-0">
      {/* Template for small screens */}
      <div className="block sm:hidden p-2 ml-3 mr-3 rounded-md"></div>

      {/* Template for medium screens */}
      <div className="hidden sm:block md:hidden bg-green-200 p-4 rounded-md">
        <h2 className="text-lg font-bold">Medium Screen Template</h2>
        <p>This template is visible on medium screens (md).</p>
      </div>

      {/* Template for large screens */}
      <div
        style={{ marginLeft: "3%", marginRight: "3%" }}
        className="hidden md:block lg:hidden bg-yellow-200 p-4 rounded-md"
      >
        <h2 className="text-lg font-bold">Large Screen Template</h2>
        <p>This template is visible on large screens (lg).</p>
      </div>

      {/* Template for extra large screens */}
      <div
        style={{ marginLeft: "5%", marginRight: "5%" }}
        className="hidden lg:block bg-red-200 p-4 rounded-md"
      >
        <h2 className="text-lg font-bold">Extra Large Screen Template</h2>
        <p>This template is visible on extra large screens (xl and above).</p>
        <div className="flex flex-row gap-2"></div>
      </div>
    </div>
  );
}
