import React, { useState, useContext, createContext } from 'react';

const CTopicContext = createContext();

export const CTopicProvider = ({ children }) => {
  const [topic, setTopic] = useState('');
  const [subtopic, setSubtopic] = useState('');
  const [selectedSubtopic, setSelectedSubtopic] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState(''); // New state for company
  const [question, setQuestion] = useState(''); // New state for questions
  const [subtype, setSubtype] = useState(''); // New state for subtype
  const [Topics, setTopics] = useState(['']);
  const [aichat, setAichat] = useState([]);
  const [userchat, setUserchat] = useState([]);

  const setTop = (newTop) => {
    setTopic(newTop);
  };

  const setSubT = (newSubT) => {
    setSubtopic(newSubT);
  };

  const setRoleContext = (newRole) => {
    setRole(newRole);
  };

  const setCompanyContext = (newCompany) => { // New function to set company
    setCompany(newCompany);
  };

  const setQuestionContext = (newQuestion) => { // New function to set questions
    setQuestion(newQuestion);
  };

  const setSubtypeContext = (newSubtype) => { // New function to set subtype
    setSubtype(newSubtype);
  };

  const addAiChat = (message) => {
    setAichat(prevAichat => [...prevAichat, [message]]);
  };

  const addUserChat = (message) => {
    setUserchat(prevUserchat => [...prevUserchat, [message]]);
  };

  return (
    <CTopicContext.Provider value={{
      Topics,
      topic,
      subtopic,
      selectedSubtopic,
      setTopics,
      setTop,
      setSubT,
      role,
      setRoleContext,
      company,
      setCompanyContext,
      question,
      setQuestionContext, 
      subtype,
      setSubtypeContext, // Provide the new function for subtype
      aichat,
      userchat,
      addAiChat,
      addUserChat
    }}>
      {children}
    </CTopicContext.Provider>
  );
};

export const useCTopic = () => {
  const context = useContext(CTopicContext);
  if (!context) {
    throw new Error('useCTopic must be used within a CTopicProvider');
  }
  return context;
};
