// UserLoader.js
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCUser } from './contexts/Cusercontext'; 
import Skeleton from '@mui/material/Skeleton';

const UserLoader = () => {
  const { setFirst, setUser, setUserIdValue } = useCUser();
  const navigate = useNavigate();
  const location = useLocation();
  const prevUserRoleRef = useRef(null); // To track the previous user role

    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('user_id');
    const firstName = queryParams.get('first_name');
    const email = queryParams.get('email');
    const userRole = queryParams.get('userrole');

    const setUserOnline = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/setstatus/online`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ linkedin_id: userId }), // Assuming userId is the LinkedIn ID
        });

        if (!response.ok) {
          console.error('Failed to set status online');
        } else {
          console.log('sey Status as online')
        }
      } catch (error) {
        console.error('Error setting status online:', error);
      }
    };

  useEffect(() => {
    

    // Set the first name and user role in context

    setFirst(firstName);
    setUser(userRole);
    setUserIdValue(userId);
    setUserOnline();

    // Only navigate if the user role has changed
    if (prevUserRoleRef.current !== userRole) {
      prevUserRoleRef.current = userRole; // Update the previous user role reference
      if (userRole === 'admin' || userRole === 'superadmin' || userRole === 'readonlyadmin') {
        navigate('/admin-dashboard');
      } else if (userRole === 'employer') {
        navigate('/employer-dashboard');
      } else {
        navigate('/dashboard');

      }
    }
  }, [location.search, navigate, setFirst, setUser]);

  return (
    <div>
      <h1 className='bg-indigo-200'>Loading...</h1>
      {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="rectangular" width={210} height={60} />
        <Skeleton variant="rounded" width={210} height={60} />
    </div>
  );
};

export default UserLoader;
