import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCTopic } from "../../contexts/Ctopiccontext";
import negative from "../../assets/audio/Negative.mp3";
import positive from "../../assets/audio/Positive1.mp3";

function Q1() {
  const { topic, setSubT } = useCTopic();
  const [selectedTopic, setSelectedTopic] = useState("");
  const [subtopics, setSubtopics] = useState([]);
  const navigate = useNavigate();

  function play1() {
    new Audio(negative).play();
  }

  function play2() {
    new Audio(positive).play();
  }

  useEffect(() => {
    const fetchSubtopics = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/retrievesubtopics`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ topic_name: topic }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch subtopics");
        }

        const data = await response.json();
        const { subtopics } = data;
        setSubtopics(subtopics);
      } catch (error) {
        console.error("Error fetching subtopics:", error);
        // Handle error, show user feedback, etc.
      }
    };

    if (topic) {
      fetchSubtopics();
    }
  }, [topic]);

  // Function to handle selection of subtopic
  const handleSubTopicSelection = (selectedSubtopic) => {
    // Handle subtopic selection logic, e.g., navigate to next screen
    setSubT(selectedSubtopic);
    play2();
    navigate("/Q2");
  };

  return (
    <div className="container lg:mx-24 mx-auto p-0 flex lg:mt-2 flex-col lg:flex-row lg:gap-10">
      {/* Layout for small and medium screens */}
      <div className="block lg:hidden ml-4 mx-3 p-6 rounded-md dark:bg-gray-800 dark:text-white">
        <div className="flex flex-col gap-2">
          <Link to="/topics">
            <button
              className="flex flex-row gap-2 p-2 w-36 h-10 bg-stone-300 dark:bg-stone-700 justify-center items-center text-black dark:text-white rounded-md hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600"
              onClick={() => play1()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              Back
            </button>
          </Link>
          <div className="text-3xl font-light">Topic Selected: {topic}</div>
          <h1 className="text-5xl font-bold mt-4 leading-tighter tracking-tighter">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
              Let's go into more detail about your selected topic
            </span>
          </h1>
          <h2 className="text-2xl font-thin">
            Choose from the list of available subtopics to make your interview
            session more detailed. The more I know, the more accurate and
            beneficial your experience will be.
          </h2>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          {subtopics.map((subtopic, index) => (
            <button
              key={index}
              className="w-full h-16 border-2 p-2 rounded-md hover:bg-stone-200 border-stone-400 hover:border-stone-200 dark:border-gray-600 dark:hover:bg-gray-700 dark:text-white"
              onClick={() => handleSubTopicSelection(subtopic)}
            >
              {subtopic}
            </button>
          ))}
        </div>
      </div>

      {/* Layout for large and extra large screens */}
      <div className="hidden lg:flex flex-row gap-10 px-12 pb-4 pt-10 mx-auto max-w-screen-xl h-auto dark:bg-gray-800 dark:text-white">
        <div className="w-full lg:w-1/2">
          <div className="text-2xl font-light mb-2">
            Topic Selected: {topic}
          </div>
          <h1 className="text-4xl font-bold mt-2 leading-tighter tracking-tighter mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
              Let's go into more detail about your selected topic
            </span>
          </h1>
          <h2 className="text-xl font-light text-gray-600 dark:text-gray-400 mb-4">
            Choose from the list of available subtopics to make your interview
            session more detailed. The more I know, the more accurate and
            beneficial your experience will be.
          </h2>
          <Link to="/topics">
            <button
              className="flex flex-row gap-2 p-2 w-36 h-10 bg-stone-300 dark:bg-stone-700 justify-center items-center text-black dark:text-white rounded-md hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600"
              onClick={() => play1()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              Back
            </button>
          </Link>
        </div>
        <div className="w-full h-full lg:w-1/2 flex flex-col rounded-md shadow-lg dark:bg-gray-700 dark:border-gray-600">
          <div className="w-full flex flex-col p-4">
            {/* First Column */}
            <div className="w-full flex flex-col gap-4">
              {subtopics
                .slice(0, Math.ceil(subtopics.length / 2))
                .map((subtopic, index) => (
                  <button
                    key={index}
                    className="h-14 rounded-md hover:bg-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-800 dark:text-white "
                    onClick={() => handleSubTopicSelection(subtopic)}
                  >
                    {subtopic}
                  </button>
                ))}
            </div>
            {/* Second Column */}
            <div className="w-full flex flex-col gap-4 mt-4">
              {subtopics.length === 0 ? (
                <div className="flex items-center justify-center h-full">
                  <p className="text-xl text-gray-500 dark:text-gray-400">
                    Coming soon
                  </p>
                </div>
              ) : (
                subtopics
                  .slice(Math.ceil(subtopics.length / 2))
                  .map((subtopic, index) => (
                    <button
                      key={index}
                      className="h-14 rounded-md hover:bg-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-800 dark:text-white "
                      onClick={() => handleSubTopicSelection(subtopic)}
                    >
                      {subtopic}
                    </button>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q1;
