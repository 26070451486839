// src/components/Home.jsx
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import chatBackgroundImage from '../../assets/images/dashboard.jpg';
import axios from 'axios';
import img from '../../assets/images/coming-soon.png'

export default function Credentials() {
 
  // Funtion to handle the fetching of subjects
  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState([]);

  const handleAddSkill = () => {
    if (skill.trim()) {
      setSkills([...skills, skill]);
      setSkill('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddSkill();
    }
  };

  console.log(`Screen width: ${window.screen.width}px`);
console.log(`Screen height: ${window.screen.height}px`);
console.log(`Viewport width: ${window.innerWidth}px`);
console.log(`Viewport height: ${window.innerHeight}px`);



  return (

    <div className="flex flex-col md:flex-row gap-10 lg:pl-14 lg:pr-12 lg:pb-4 lg:pt-10 md:p-16  mx-auto max-w-screen-xl h-auto">

      <div style={{ width: '30%'}}>
        <h1 className='font-extrabold text-5xl ml-8'>Revision Time</h1>
        <h2 className='text-lg ml-8 mt-4'>This page contains all your past interview simulation attempts for you to review and learn from your mistakes.</h2>
        <h2 className='font-bold text-lg ml-8 mt-2'>Please select the attempt that you would like to review</h2>
        <Link to='/Q1'>
        <button className='flex flex-row gap-2 p-2 w-36 h-10 ml-8 bg-black justify-center items-center text-white rounded-md mt-4 hover:bg-stone-300 hover:text-black'>Review
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
          </svg>
        </button>
        </Link>
      </div>
      <div style={{ width: '75%' }} className="flex flex-col bg-white rounded-md shadow-lg p-4">
        <input className='w-48 h-10 bg-stone-100 mb-2 rounded-md pl-2 focus:outline-none text-sm' placeholder='Search..'></input>
      <table className="min-w-full divide-y divide-gray-200 rounded-md">
        <thead className="bg-stone-200">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Attempt No.</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Topic</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtopic</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Attempted Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grade</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr className='hover:bg-stone-100'>
            <td className="px-6 py-4 whitespace-nowrap">1</td>
            <td className="px-6 py-4 whitespace-nowrap">Product</td>
            <td className="px-6 py-4 whitespace-nowrap">User Research</td>
            <td className="px-6 py-4 whitespace-nowrap">2024-06-14</td>
            <td className="px-6 py-4 whitespace-nowrap">A</td>
          </tr>
          <tr className='hover:bg-stone-100'>
            <td className="px-6 py-4 whitespace-nowrap">2</td>
            <td className="px-6 py-4 whitespace-nowrap">Engineering</td>
            <td className="px-6 py-4 whitespace-nowrap">Backend</td>
            <td className="px-6 py-4 whitespace-nowrap">2024-06-15</td>
            <td className="px-6 py-4 whitespace-nowrap">B+</td>
          </tr>
          <tr className='hover:bg-stone-100'>
            <td className="px-6 py-4 whitespace-nowrap">3</td>
            <td className="px-6 py-4 whitespace-nowrap">Consulting</td>
            <td className="px-6 py-4 whitespace-nowrap">Strategy</td>
            <td className="px-6 py-4 whitespace-nowrap">2024-06-16</td>
            <td className="px-6 py-4 whitespace-nowrap">A-</td>
          </tr>
          
        </tbody>
      </table>
</div>

      
     
    </div>

  




   
  

   
  );
}
