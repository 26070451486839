import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { Alert, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function AdminApplication() {
  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState(0); // State to track the selected tab
  const [successMsg, setsuccessMsg] = useState("");
  const [successMsgOpen, setsuccessMsgOpen] = useState(false);

  // Function to close the notification for Success messages
  const handleSuccessMsgClose = async () => {
    setsuccessMsgOpen(false);
    setsuccessMsg("");
  };

  useEffect(() => {
    fetchAdminApplications();
  }, []);

  const fetchAdminApplications = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/fetchadminapplications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Admin Applications:", data);
        setApplications(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAction = (requestId, action) => {
    // Implement the action handling logic (accept or decline)
    console.log(`Request ID: ${requestId}, Action: ${action}`);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatUrl = (url) => {
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`;
    }
    return url;
  };

  // Filter applications based on the selected tab
  const filteredApplications = applications.filter((app) => {
    if (value === 0) return app.status === "Pending";
    if (value === 1) return app.status === "Approved";
    if (value === 2) return app.status === "Declined";
    return true;
  });

  const handlePendingButtonClick = () => {
    setValue(0); // Sets the tab to "Pending"
  };

  const handleApprovedButtonClick = () => {
    setValue(1); // Sets the tab to "Pending"
  };

  const handleDeclinedButtonClick = () => {
    setValue(2); // Sets the tab to "Pending"
  };

  // Calculate totals for each tab
  const totalPending = applications.filter(
    (app) => app.status === "Pending"
  ).length;
  const totalApproved = applications.filter(
    (app) => app.status === "Approved"
  ).length;
  const totalDeclined = applications.filter(
    (app) => app.status === "Declined"
  ).length;

  const updateApplicationStatus = (requestId, newStatus) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/updateapplicationstatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request_id: requestId,
        status: newStatus,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Status Updated:", data);
        fetchAdminApplications();
        setsuccessMsg(`Sucessfully ${newStatus} Request`);
        setsuccessMsgOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleAccept = (requestId) => {
    updateApplicationStatus(requestId, "Approved");
  };

  const handleDecline = (requestId) => {
    updateApplicationStatus(requestId, "Declined");
  };

  const handlePending = (requestId) => {
    updateApplicationStatus(requestId, "Pending");
  };

  return (
    <div className="container ml-auto mr-auto mt-8">
      <div className="flex flex-row gap-4">
        <button
          className="w-24 text-sm p-2 bg-stone-200 text-black hover:bg-black hover:text-stone-200 hover:cursor rounded-md"
          onClick={handlePendingButtonClick}
        >
          Admin List
        </button>
        <button
          className="w-24 text-sm p-2 bg-stone-200 text-black hover:bg-black hover:text-stone-200 hover:cursor rounded-md"
          onClick={handlePendingButtonClick}
        >
          Pending
        </button>
        <button
          className="w-24 text-sm p-2 bg-stone-200 text-black hover:bg-black hover:text-stone-200 hover:cursor rounded-md"
          onClick={handleApprovedButtonClick}
        >
          Approved
        </button>
        <button
          className="w-24 text-sm p-2 bg-stone-200 text-black hover:bg-black hover:text-stone-200 hover:cursor rounded-md"
          onClick={handleDeclinedButtonClick}
        >
          Declined
        </button>
      </div>

      <div className="flex flex-row gap-5 mt-4 mb-2">
        <h1 className="text-lg font-semibold">Admin Setup</h1>

        {/* Conditional rendering of header text */}
        {value === 0 && (
          <h1 className="text-lg font-semibold ml-auto">
            Total Pending: {totalPending}
          </h1>
        )}
        {value === 1 && (
          <h1 className="text-lg font-semibold ml-auto">
            Total Approved: {totalApproved}
          </h1>
        )}
        {value === 2 && (
          <h1 className="text-lg font-semibold ml-auto">
            Total Declined: {totalDeclined}
          </h1>
        )}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>LinkedIn Profile URL</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role Applied</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApplications
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((app) => (
                <TableRow key={app.request_id}>
                  <TableCell>{app.request_id}</TableCell>
                  <TableCell>
                    <a
                      href={formatUrl(app.linkedin_profile_url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline block max-w-32 overflow-hidden text-ellipsis"
                    >
                      {app.linkedin_profile_url}
                    </a>
                  </TableCell>
                  <TableCell className=" block max-w-48 overflow-hidden text-ellipsis">
                    {app.email}
                  </TableCell>
                  <TableCell>{app.role_applied}</TableCell>
                  <TableCell>{app.status}</TableCell>
                  <TableCell>
                    {new Date(app.timestamp).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {value === 0 && (
                      <>
                        <Button
                          onClick={() => handleAccept(app.request_id)}
                          variant="contained"
                          color="success"
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() => handleDecline(app.request_id)}
                          variant="contained"
                          color="error"
                          sx={{ ml: 1 }}
                        >
                          Decline
                        </Button>
                      </>
                    )}
                    {value === 1 && (
                      <>
                        <Button
                          onClick={() => handleDecline(app.request_id)}
                          variant="contained"
                          color="error"
                        >
                          Decline
                        </Button>
                        <Button
                          onClick={() => handlePending(app.request_id)}
                          variant="contained"
                          color="warning"
                          sx={{ ml: 1 }}
                        >
                          Pending
                        </Button>
                      </>
                    )}
                    {value === 2 && (
                      <>
                        <Button
                          onClick={() => handleAccept(app.request_id)}
                          variant="contained"
                          color="success"
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={() => handlePending(app.request_id)}
                          variant="contained"
                          color="warning"
                          sx={{ ml: 1 }}
                        >
                          Pending
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredApplications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      {/* Notification for Success */}
      <Snackbar
        open={successMsgOpen}
        autoHideDuration={3000}
        onClose={handleSuccessMsgClose}
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon style={{ marginRight: "8px", color: "#4caf50" }} />
            {`${successMsg}`}
          </span>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSuccessMsgClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}

export default AdminApplication;
