import { createContext, useContext, useState, useEffect } from 'react';

const CUserContext = createContext();

export const CUserProvider = ({ children }) => {
  const [loggedstatus, setLoggedstatus] = useState('');
  const [usertype, setUsertype] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmailaddress] = useState('');
  const [menu, setMenuoption] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false); // Dark mode state
  const [userId, setUserId] = useState(''); // Added userId state
  

  // Toggle dark mode function
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Apply dark mode class to the HTML element
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const setUser = (newUsertype) => {
    setUsertype(newUsertype);
  };

  const setFirst = (newFirstname) => {
    setFirstname(newFirstname);
  };

  const setLast = (newLastname) => {
    setLastname(newLastname);
  };

  const setEmail = (newEmail) => {
    setEmailaddress(newEmail);
  };

  const setMenu = (newMenu) => {
    setMenuoption(newMenu);
  };

  const setUserIdValue = (newUserId) => {
    setUserId(newUserId);
  };

  return (
    <CUserContext.Provider
      value={{
        userId,               // Added userId to context value
        usertype,
        firstname,
        lastname,
        email,
        menu,
        isDarkMode,
        toggleDarkMode,
        setUser,
        setFirst,
        setLast,
        setEmail,
        setMenu,
        setUserIdValue, // Expose setUserId function
      }}
    >
      {children}
    </CUserContext.Provider>
  );
};

export const useCUser = () => {
  const context = useContext(CUserContext);
  if (!context) {
    throw new Error('useCUser must be used within a CUserProvider');
  }
  return context;
};
