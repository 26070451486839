// components/Layout.js
import React, { useState, useRef, useEffect } from 'react';
import Sidenav from './candidate/Newnav';
import { useCTopic } from '../contexts/Ctopiccontext';

const MainLayout = ({ children }) => {
  const {Topics, setTopics} = useCTopic();

     // Function to fetch topics from Flask backend

     const fetchTopics = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/retrievetopics`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch topics');
        }
  
        const data = await response.json();
        const { topics } = data;
        setTopics(topics); // Set state with fetched topics array
      } catch (error) {
        console.error('Error fetching topics:', error);
        // Handle error, show user feedback, etc.
      }
    };
  
    useEffect(() => {
      fetchTopics();
    }, []); // Empty dependency array ensures this runs only once on mount
  
  return (
  <div  className="flex flex-col h-screen  dark:bg-gray-800 ">
    <Sidenav />
    <div className="lg:mt-16 mt-2  dark:bg-gray-800 pt-4 rounded-md">
      {children}
    </div>
  </div>

   
  );
};

export default MainLayout;
