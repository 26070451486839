import React, { useState } from 'react';
import { useCTopic } from '../../contexts/Ctopiccontext'; // Import your context hook

const MyForm = () => {
  return (
    <div>
      <div className=' flex flex-row pl-4 items-center justify-start text-medium font-semibold bg-stone-300 rounded-md h-14'>Subtype Level Overview

        <div className='p-2'></div>


      </div>
      <div className='p-2'>
      <table className="min-w-full divide-y divide-gray-200 rounded-md">
        <thead className="bg-stone-200">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Topic</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtopic</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtype</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prompt</th>
           
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr className='hover:bg-stone-100'>
            <td className="px-6 py-4 whitespace-nowrap"><span className='p-1 bg-orange-200 rounded-md'>Incomplete</span></td>
            <td className="px-6 py-4 whitespace-nowrap">Product</td>
            <td className="px-6 py-4 whitespace-nowrap">Product Sense and Design</td>
            <td className="px-6 py-4 whitespace-nowrap">Design From Scratch</td>
            <td className="px-6 py-4 whitespace-nowrap">Default</td>
          </tr>
          <tr className='hover:bg-stone-100'>
            <td className="px-6 py-4 whitespace-nowrap">2</td>
            <td className="px-6 py-4 whitespace-nowrap">Engineering</td>
            <td className="px-6 py-4 whitespace-nowrap">Backend</td>
            <td className="px-6 py-4 whitespace-nowrap">2024-06-15</td>
            <td className="px-6 py-4 whitespace-nowrap">B+</td>
          </tr>
          <tr className='hover:bg-stone-100'>
            <td className="px-6 py-4 whitespace-nowrap">3</td>
            <td className="px-6 py-4 whitespace-nowrap">Consulting</td>
            <td className="px-6 py-4 whitespace-nowrap">Strategy</td>
            <td className="px-6 py-4 whitespace-nowrap">2024-06-16</td>
            <td className="px-6 py-4 whitespace-nowrap">A-</td>
          </tr>
          
        </tbody>
      </table>
      </div>
    
   
  

    </div>
  );
};

export default MyForm;
