import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useCUser } from './contexts/Cusercontext'; // Adjust the path as needed
import img from './assets/images/access-denied.png';

const SuperAdminRoute = ({ children }) => {
  const { usertype } = useCUser();
  const [timer, setTimer] = useState(false);
  const [redirectPath, setRedirectPath] = useState(false);

  console.log(usertype);

  useEffect(() => {
    if (usertype !== 'superadmin') {
      setTimer(true);
      setTimeout(() => {
        setRedirectPath('/admin-dashboard');
        // Reset timer state after redirection
      }, 7000);

    }
  }, [usertype]);

  if (redirectPath) {
    return <Navigate to={redirectPath} />; // Redirect if needed
  }

  if (timer) {
    return (
      <div className='flex items-center justify-center h-screen p-8 mx-auto mr-8'>
        {/* Your custom message */}
        <div style={{ width: '60%', height: '90%',}}
          className=' bg-white border-2 border-red-400 rounded-md p-5 shadow-lg flex flex-col justify-center items-center'
        >
          <h1 className='font-extrabold text-3xl text-center text-red-800 mt-4'>
            Oops! Looks Like You Don't Have Permission to Enter the Manage Simulator Page!
          </h1>
          <h2 className='mt-4 text-lg text-gray-600 text-center'>
            Maybe it was an accident or perhaps you were trying to access a restricted URL. Either way you will be navigated back to the Guest Mode in a few seconds.
          </h2>
          {/* Add your image import and usage */}
          <img src={img} className='w-56 h-48 mt-6' alt="Access Denied" />
        </div>
      </div>
    );
  }

  return children; // Render the children if the user is an admin
};

export default SuperAdminRoute;

