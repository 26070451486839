// src/components/Home.jsx
import React, { useState } from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import chatBackgroundImage from "../assets/images/welcome.png";
import { Alert, Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Home() {
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [role, setRole] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [successMsgOpen, setsuccessMsgOpen] = useState(false);

  // Function to close the notification for Success messages
  const handleSuccessMsgClose = async () => {
    setsuccessMsgOpen(false);
    setsuccessMsg("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const apply = () => {
    console.log(email);
    console.log(url);
    console.log(role);
    fetch("http://localhost:5000/api/applyadmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Email: email, URL: url, Role: role }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setsuccessMsg("Successfully Applied");
        setsuccessMsgOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container mx-auto p-0">
      <main className="md:px-12 flex flex-col flex-1 justify-center">
        {/* Layout for small and medium screens */}
        <div
          style={{ height: "100vh", marginTop: "10vh" }}
          className="block pb-72 lg:hidden p-6 mx-auto mt-6 rounded-md dark:bg-gray-800 dark:text-white"
        >
          <div className="flex flex-col gap-2 items-center">
            {/* 4th block */}
            <div className="w-72 mt-8 md:mt-16 text-center">
              <span className="bg-clip-text text-5xl font-medium text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                Join Our New Admin Program
              </span>
              <p className="w-72 mt-4 text-justify text-xl text-gray-600 dark:text-gray-400 mb-4">
                Join Our Team: Shape the Future of Interview Preparation with
                AVA and get exciting perks
              </p>
              <form>
                <div className="flex flex-wrap mb-4">
                  <div className="w-56">
                    <label
                      className="block text-sm sr-only"
                      htmlFor="newsletter"
                    >
                      Email Connected to Linkedin
                    </label>
                    <div className="relative flex flex-col gap-2 items-center ml-16">
                      <input
                        onChange={handleEmailChange}
                        id="newsletter"
                        type="email"
                        className="form-input w-72 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-4 py-3 text-md focus:outline-none"
                        placeholder="Email Connected to Linkedin"
                        required
                      />
                      <input
                        onChange={handleUrlChange}
                        id="newsletter"
                        type="email"
                        className="form-input w-72 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-4 py-3 text-md focus:outline-none"
                        placeholder="Linkedin Profile URL"
                        required
                      />
                      <select
                        onChange={handleRoleChange}
                        id="newsletter"
                        className="form-input w-72 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-4 py-3 text-md focus:outline-none"
                        required
                      >
                        <option value="" disabled hidden>
                          Select an Option
                        </option>
                        <option value="superadmin">Super Admin</option>
                        <option value="admin">Admin</option>
                        <option value="readonlyadmin">Read Only Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <button
                onClick={apply}
                className="flex flex-row text-md gap-2 px-4 py-3 w-72 h-12 mt-6 bg-black justify-center items-center text-white rounded-md mt-4 hover:bg-stone-300 hover:text-black"
              >
                Apply Now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Layout for large and extra-large screens */}
        <div
          style={{ height: "100vh" }}
          className="hidden lg:flex w-full md:w-11/12 xl:w-4/5 lg:h-2/5 xl:h-4/5 md:h-4/12 flex-col md:flex-row text-left pt-0 pl-10 pb-24 pr-10 mb-4 rounded-2xl mx-auto mt-8 mb-12"
        >
          <div className="md:w-1/2 md:ml-6 md:mt-16">
            {/* 4th block */}
            <div className="ml-4 mt-8 sm:col-span-6 md:col-span-3 lg:col-span-3">
              <span className="bg-clip-text text-5xl font-medium text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                Join Our Admin Program
              </span>
              <p className="w-48 mt-4 text-sm text-gray-600 dark:text-gray-400 mb-4">
                Join Our Team: Shape the Future of Interview Preparation with
                AVA and get exciting perks
              </p>
              <form>
                <div className="flex flex-wrap mb-4">
                  <div className="w-56">
                    <label
                      className="block text-sm sr-only"
                      htmlFor="newsletter"
                    >
                      Email Connected to Linkedin
                    </label>
                    <div className="relative flex flex-col gap-2 items-center max-w-xs">
                      <input
                        onChange={handleEmailChange}
                        id="newsletter"
                        type="email"
                        className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                        placeholder="Email Connected to Linkedin"
                        required
                      />
                      <input
                        onChange={handleUrlChange}
                        id="newsletter"
                        type="email"
                        className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                        placeholder="Linkedin Profile URL"
                        required
                      />
                      <select
                        onChange={handleRoleChange}
                        id="newsletter"
                        className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                        required
                      >
                        <option value="" disabled hidden>
                          Select an Option
                        </option>
                        <option value="superadmin">Super Admin</option>
                        <option value="admin">Admin</option>
                        <option value="readonlyadmin">Read Only Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <button
              onClick={apply}
              className="flex flex-row gap-2 p-2 ml-4 w-36 h-10 mt-6 bg-black justify-center items-center text-white rounded-md mt-4 hover:bg-stone-300 hover:text-black"
            >
              Apply Now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
            </button>
          </div>

          <div className="flex flex-row gap-4 md:w-4/12 mt-12 md:mt-1/2 ml-4 lg:w-6/12">
            <img
              src={chatBackgroundImage}
              alt="Chat Background"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Notification for Success */}
        <Snackbar
          open={successMsgOpen}
          autoHideDuration={3000}
          onClose={handleSuccessMsgClose}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon
                style={{ marginRight: "8px", color: "#4caf50" }}
              />
              {`${successMsg}`}
            </span>
          }
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSuccessMsgClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </main>
    </div>
  );
}
