// src/components/Home.jsx
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import chatBackgroundImage from '../../assets/images/dashboard.jpg';
import axios from 'axios';

export default function Credentials() {
 
  // Funtion to handle the fetching of subjects
  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState([]);

  const handleAddSkill = () => {
    if (skill.trim()) {
      setSkills([...skills, skill]);
      setSkill('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddSkill();
    }
  };
  return (
    <div style={{ height: '104%' }} className='flex flex-row flex-grow'>
      <div class="bg-white flex-grow ml-4 mr-4 items-left justify-left rounded-md max-h-screen">

        {/* Title and Subtitle */}
        <div className='flex flex-row gap-4'>
            <div>
                <h1 class="pt-2 pl-4 text-2xl font-extrabold text-left text-black">My Credentials</h1>
                <p className="text-sm pl-4 text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                    Manage and Update your resume in one place.
                </p>
            </div>
            <div className='flex flex-row gap-4 mt-4 ml-auto mr-6 justify-start'>
                <Link to='/credentials'> 
                  <h1 class="lg:w-32 xl:w-48 lg:text-xs pt-2 text-center font-semibold bg-gray-200 hover:bg-indigo-200  rounded-md p-2">Profile and Skillset</h1>
                </Link>
                <Link to='/experience'> 
                  <h1 class="lg:w-32 xl:w-48 lg:text-xs pt-2 text-center font-semibold bg-indigo-200 rounded-md p-2">Education and Work</h1>
                </Link>
                <Link to='/certifications'> 
                  <h1 class="lg:w-32 xl:w-48 lg:text-xs pt-2 text-center font-semibold bg-gray-200 hover:bg-indigo-200 rounded-md p-2">Certifications</h1>
                </Link>
            </div>
        </div>

        <div className='flex flex-col' style={{ width: '100%', height: '90%' }}>

        

        {/* Second Row - Other Cards */}
        <div className='flex flex-row mt-4 mb-4' style={{ width: '100%', height: '100%' }}>
          {/* 1st Card */}
          <div  style={{ width: '96%', height: '100%' }}  className="flex flex-row ml-4 gap-4  rounded-md">
            
            <div style={{ width: '100%', height: '99%' }} className='flex flex-col bg-slate-200 p-4 rounded-md'>
             <h1 className='text-sm font-semibold'>My Work Experience</h1>
             <hr className='h-4'></hr>
             
             

            </div>


            <div style={{ width: '100%', height: '99%' }} className="flex flex-col bg-slate-200 rounded-md p-4" >
              <h1 className="text-sm font-semibold mb-2">My Education</h1>
              <div className='border-white bg-white mr-40 rounded-md p-2'>
                <h1 className='text-sm'>University ABC</h1>
                <h1 className='text-sm'>Bachelor of Computer Science</h1>
                <h1 className='text-sm'>7.6/10 CGPA</h1>
                <h1 className='text-sm'>March 20 to March 23</h1>
              </div>
              <div className='border-white bg-white mr-40 rounded-md p-2 mt-2'>
                <h1 className='text-sm'>University ABC</h1>
                <h1 className='text-sm'>Bachelor of Computer Science</h1>
                <h1 className='text-sm'>7.6/10 CGPA</h1>
                <h1 className='text-sm'>March 20 to March 23</h1>
              </div>
              <form className='p-2 bg-slate-400 mr-40 rounded-md mt-4'>
                <input className='text-xs p-2 focus:outline-none font-semibold bg-white rounded-md w-80' placeholder='Instituition Name'></input>
                <input className='text-xs p-2 focus:outline-none font-semibold bg-white rounded-md w-80 mt-2' placeholder='Course Name'></input>
                <input className='text-xs p-2 focus:outline-none font-semibold bg-white rounded-md w-80 mt-2' placeholder='Grade'></input>
                <div className='flex flex-row gap-4'>
                  <input type='date' className='text-xs p-2 focus:outline-none font-semibold bg-white rounded-md w-36 mt-2' placeholder='Start Date'></input>
                  <input type='date' className='text-xs p-2 focus:outline-none font-semibold bg-white rounded-md w-36 mt-2' placeholder='End Date'></input>
                </div>
                <button className='p-2 text-xs bg-indigo-200 mt-2 rounded-md hover:bg-indigo-400 ml-auto mr-4'>Add</button>
             </form>
               
                
                
            </div>
            
          </div>

        </div>

        </div>
     
      </div>
    </div>


   
  );
}
