import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/coming-soon.png";

function Setting() {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("Account Info");

  const [selected, setSelected] = useState("Account Info");

  console.log(selected);

  // Function to set the topic when a button is clicked
  const handleSectionSelection = (selectedSection) => {
    setSelected(selectedSection);
    console.log(selected);
  };

  return (
    <div className="container mx-auto">
      {/* Template for small and medium screens */}
      <div className="block lg:hidden p-2 ml-3 mr-3 rounded-md">
        <div className="flex flex-row gap-4 text-center">
          <div
            className={`p-2 text-gray-600 dark:text-gray-300 w-6/12 h-10 rounded-md ${
              selected === "Job"
                ? "bg-gray-300 dark:bg-gray-600"
                : "bg-gray-100 dark:bg-gray-700"
            }`}
            onClick={() => handleSectionSelection("Account Info")}
          >
            Account Info
          </div>
          <div
            className={`p-2 text-gray-600 dark:text-gray-300 w-6/12 h-10 rounded-md ${
              selected === "App"
                ? "bg-gray-300 dark:bg-gray-600"
                : "bg-gray-100 dark:bg-gray-700"
            }`}
            onClick={() => handleSectionSelection("Billing")}
          >
            Billing
          </div>
          <div
            className={`p-2 text-gray-600 dark:text-gray-300 w-6/12 h-10 rounded-md ${
              selected === "Resume"
                ? "bg-gray-300 dark:bg-gray-600"
                : "bg-gray-100 dark:bg-gray-700"
            }`}
            onClick={() => handleSectionSelection("Feedback")}
          >
            Feedback
          </div>
        </div>
        <hr className="mt-4"></hr>
        <h1 className="text-3xl font-light dark:text-gray-300 mt-4">
          {selected === "Account Info"
            ? "Account Info"
            : selected === "Billing"
            ? "My Billing"
            : "Feedback"}
        </h1>

        {selected === "Account Info" && (
          <div className="bg-blue-200 rounded-md mt-4 p-2">
            <h2 className="text-lg font-bold">Account Info</h2>
            <p>This template is visible on small screens (sm and below).</p>
          </div>
        )}

        {selected === "Billing" && (
          <div className="bg-green-200 rounded-md mt-4 p-2">
            <h2 className="text-lg font-bold">Billing</h2>
            <p>This template is visible on medium screens (md and above).</p>
          </div>
        )}

        {selected === "Feedback" && (
          <div className="bg-yellow-200 rounded-md mt-4 p-2">
            <h2 className="text-lg font-bold">Feedback</h2>
            <p>This template is visible on large screens (lg and above).</p>
          </div>
        )}
      </div>

      {/* Template for large screens */}
      <div className="hidden lg:block p-2 ml-3 mr-3 rounded-md">
        <div className="flex flex-row gap-4 mb-4">
          {/* Tabs */}
          {["Account Info", "Billing", "Feedback"].map((tab) => (
            <button
              key={tab}
              className={`w-24 text-sm p-2 rounded-md ${
                activeTab === tab
                  ? "bg-black text-stone-200"
                  : "bg-stone-200 text-black hover:bg-black hover:text-stone-200"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Conditional divs based on active tab */}
        <div className="p-4 bg-white  rounded-md">
          {activeTab === "Account Info" && (
            <div>
              {/* Content for Account Info */}
              <p>Account Info content goes here.</p>
            </div>
          )}
          {activeTab === "Billing" && (
            <div className="flex flex-col gap-4">
              {/* First Row */}
              <div
                style={{ width: "100%" }}
                className="flex flex-row gap-4 w-full "
              >
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                  <p className="text-gray-800 dark:text-gray-200">
                    Cost/100 TKNS - $20
                  </p>
                </div>
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                  <p className="text-gray-800 dark:text-gray-200">
                    Cost/Simulation - $2
                  </p>
                </div>
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                  <p className="text-gray-800 dark:text-gray-200">
                    Total Tokens Bought This Month: 2500 TKNS
                  </p>
                </div>
              </div>

              {/* Second Row */}
              <div style={{ width: "100%" }} className="flex flex-row gap-4">
                <div
                  style={{ width: "33%" }}
                  className="p-4 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-md"
                >
                  <p className="text-gray-800 dark:text-gray-200">
                    Total Tokens Used This Month: 2000 TKNS
                  </p>
                </div>
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                  <p className="text-gray-800 dark:text-gray-200">
                    Total Recharged This Month:{" "}
                  </p>
                </div>
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                  <p className="text-gray-800 dark:text-gray-200">
                    Billing content goes here.
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab === "Feedback" && (
            <div className="flex flex-row gap-24">
              {/* Content for Feedback */}
              <div className="w-60 text-justify">
                <h1 className="text-3xl font-bold leading-tighter tracking-tighter mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                    Your Feedback is important for us.
                  </span>
                </h1>
                <div className="max-w-3xl">
                  <p className="text-xl font-light text-gray-600 dark:text-gray-400 mb-4">
                    Fill up this short feedback form to notify us of any
                    potential bugs, issues or suggestions you may have.
                  </p>
                  <p className="text-lg font-medium text-gray-600 dark:text-gray-400 mb-8">
                    Please select the topic that you would like to practice
                  </p>
                </div>
              </div>
              <form>
                <div className="flex flex-wrap mb-4">
                  <div className="w-full md:w-96 mt-4">
                    {/* Query Type Select */}
                    <select
                      id="queryType"
                      className="form-input w-full mb-2 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                      required
                    >
                      <option value="" disabled hidden>
                        Query Type
                      </option>
                      <option value="suggestion">Suggestion</option>
                      <option value="bug">Reporting a Bug</option>
                      <option value="issue">Issue</option>
                    </select>

                    {/* Textarea for Description */}
                    <textarea
                      id="description"
                      className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none mb-2"
                      placeholder="Describe your issue"
                      style={{ width: "100%", height: "100px" }}
                      required
                    />

                    {/* File Attachment */}
                    <div className="mb-2">
                      <label
                        htmlFor="fileAttachment"
                        className="block text-sm font-medium text-gray-800 dark:text-gray-200"
                      >
                        Attach File (if necessary)
                      </label>
                      <input
                        id="fileAttachment"
                        type="file"
                        className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Setting;
