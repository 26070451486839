import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCUser } from "../contexts/Cusercontext";

function Header() {
  const [top, setTop] = useState(true);
  const { firstname, usertype, menu, setUser, setMenu, setFirst } = useCUser();
  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useCUser();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const handleMenuSelection = (selectedMenu) => {
    setMenu(selectedMenu);
    navigate("/" + selectedMenu);
    setIsHamburgerMenuOpen(false); // Close the menu after selection
  };

  const handleHamburgerToggle = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full bg-white max-w-8xl mx-auto px-4 sm:px-6 dark:bg-gray-800 z-0 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white backdrop-blur-sm shadow-lg"
      }`}
    >
      <div className="flex items-center justify-between sm:h-24 h-12 md:h-16">
        {/* Site branding */}
        <div className="flex-shrink-0  mr-4 md:ml-8 text-light text-xl dark:text-white">
          <Link to="/">
            <span className="ml-4">A</span>
            <span className="text-slate-500">V</span>
            <span>A</span>
          </Link>
        </div>

        {/* Site navigation */}
        <nav className="hidden md:flex flex-grow">
          <ul className="flex flex-grow justify-end items-center">
            <Link to="/sign-in">
              <li className="flex flex-row gap-2 items-center justify-center pt-2 pb-2 pl-4 pr-4 rounded-md hover:text-black text-light text-black dark:text-gray-400 dark:hover:text-white text-sm ml-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>
                Sign in
              </li>
            </Link>
            <Link to="/apply-admin">
              <li className="flex flex-row gap-2 items-center justify-center pt-2 pb-2 pl-4 pr-4 rounded-md hover:text-black text-light text-black dark:text-gray-400 dark:hover:text-white text-sm ml-4 mr-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                  <path
                    fillRule="evenodd"
                    d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z"
                    clipRule="evenodd"
                  />
                  <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                </svg>
                Admin Program
              </li>
            </Link>
            <li className="ml-4">
              <button
                onClick={toggleDarkMode}
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700 rounded-full shadow transition duration-150 ease-in-out p-1.5 hover:cursor-pointer"
                aria-label="Toggle Dark Mode"
              >
                {isDarkMode ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 fill-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 fill-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                    />
                  </svg>
                )}
              </button>
            </li>
          </ul>
        </nav>

        {/* Hamburger Menu for Small Screens */}
        <div className="flex md:hidden ml-auto mr-4 flex flex-row gap-4">
          <button
            onClick={handleHamburgerToggle}
            className="focus:outline-none text-black dark:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
          <div className="">
            <button
              onClick={toggleDarkMode}
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700 rounded-full shadow transition duration-150 ease-in-out p-1.5 hover:cursor-pointer"
              aria-label="Toggle Dark Mode"
            >
              {isDarkMode ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 fill-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5 fill-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* Dropdown Menu for Small Screens */}
        {isHamburgerMenuOpen && (
          <div className="absolute top-12 left-0 w-full bg-gray-100 dark:bg-gray-700 shadow-md md:hidden">
            <ul className="flex flex-col items-center">
              <li className="w-full">
                <button
                  onClick={() => handleMenuSelection("sign-in")}
                  className="w-full text-left px-4 py-2 text-sm font-semibold text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Sign In
                </button>
              </li>
              <li className="w-full">
                <button
                  onClick={() => handleMenuSelection("apply-admin")}
                  className="w-full text-left px-4 py-2 text-sm font-semibold text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Admin Program
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
