// src/components/Home.jsx
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import chatBackgroundImage from '../../assets/images/dashboard.jpg';
import axios from 'axios';

export default function Credentials() {
 
  // Funtion to handle the fetching of subjects
  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState([]);

  const handleAddSkill = () => {
    if (skill.trim()) {
      setSkills([...skills, skill]);
      setSkill('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddSkill();
    }
  };
  return (
    <div style={{ height: '104%' }} className='flex flex-row flex-grow'>
      <div class="bg-white flex-grow ml-4 mr-4 items-left justify-left rounded-md max-h-screen">

        {/* Title and Subtitle */}
        <div className='flex flex-row gap-4'>
            <div>
                <h1 class="pt-2 pl-4 text-2xl font-extrabold text-left text-black">My Credentials</h1>
                <p className="text-sm pl-4 text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                    Manage and Update your resume in one place.
                </p>
            </div>
            <div className='flex flex-row gap-4 mt-4 ml-auto mr-6 justify-start'>
                <Link to='/credentials'> 
                  <h1 class="lg:w-32 xl:w-48 lg:text-xs pt-2 text-center font-semibold bg-gray-200 hover:bg-indigo-200  rounded-md p-2">Profile and Skillset</h1>
                </Link>
                <Link to='/experience'> 
                  <h1 class="lg:w-32 xl:w-48 lg:text-xs pt-2 text-center font-semibold bg-gray-200 hover:bg-indigo-200 rounded-md p-2">Education and Work</h1>
                </Link>
                <Link to='/certifications'> 
                  <h1 class="lg:w-32 xl:w-48 lg:text-xs pt-2 text-center font-semibold bg-indigo-200 rounded-md p-2">Certifications</h1>
                </Link>
            </div>
        </div>

        <div className='flex flex-col' style={{ width: '100%', height: '90%' }}>

        

        {/* Second Row - Other Cards */}
        <div className='flex flex-row mt-4 mb-4' style={{ width: '100%', height: '100%' }}>
          {/* 1st Card */}
          <div  style={{ width: '96%', height: '97%' }}  className="flex flex-col ml-4  rounded-md">
            <div style={{ width: '100%', height: '50%' }} className='flex flex-row bg-slate-200  rounded-md'>
             <h1 className='ml-2 mt-2 text-sm font-semibold'>My Profile</h1>
            </div>


            <div style={{ width: '100%', height: '55%' }} className="flex flex-col bg-slate-200 mt-6 rounded-md p-4">
      <h1 className="text-sm font-semibold mb-2">My Skillset</h1>
      <div className="flex flex-row mb-2">
        <input
          type="text"
          value={skill}
          onChange={(e) => setSkill(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-grow p-2 border rounded-md"
          placeholder="Add a skill"
        />
        <button
          onClick={handleAddSkill}
          className="ml-2 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add
        </button>
      </div>
      <div className="flex flex-wrap">
        {skills.map((skill, index) => (
          <div key={index} className="p-2 bg-white border rounded-md mr-2 mb-2">
            {skill}
          </div>
        ))}
      </div>
    </div>

            
          </div>


        </div>

        </div>
     

      </div>
    </div>

      

     

      
   
  );
}
