import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useCUser } from "../contexts/Cusercontext";
import axios from "axios";

import LinkedInLogin from "react-linkedin-login-oauth2";

export default function SignIn() {
  const { setUsername } = useCUser();
  const { setUser } = useCUser();
  const { setFirst } = useCUser();
  const { setLast } = useCUser();
  const { setEmail } = useCUser();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const [tab, setTab] = useState(1);

  const [selectedLogin, setSelectedlogin] = useState("");

  const handleLoginselection = (value) => {
    setSelectedlogin(value);
  };

  useEffect(() => {
    const login = () => {
      console.log(selectedLogin);
      console.log(process.env.REACT_APP_API_URL);
      fetch(`${process.env.REACT_APP_API_URL}/api/login_type`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ login_type: selectedLogin }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          handleLinkedInLogin();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (selectedLogin !== "") {
      login();
    }
  }, [selectedLogin]);

  useEffect(() => {}, [tab]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await res.json();
      console.log(data);

      if (data.success === false) {
        setLoading(false);
        setError(data.message);
        return;
      } else if (data.success === true) {
        console.log("Username: ", data.username);
        console.log("First_Name: ", data.first_name);
        console.log("Last_Name: ", data.last_name);
        console.log("Email: ", data.email);

        // Login successful
        setUser(data.username);
        // Log the updated username directly
        console.log("Username set in global context as:", data.username);

        setFirst(data.first_name);
        // Log the updated firstname directly
        console.log("Firstname set in global context as:", data.first_name);
        setLast(data.last_name);
        // Log the updated lastname directly
        console.log("Lastname set in global context as:", data.last_name);
        setEmail(data.email);
        // Log the updated email directly
        console.log("Email set in global context as:", data.email);

        // Log the updated username directly
        console.log("Username set in global context as:", data.username);

        setLoading(false);
        setError(null);

        navigate("/dashboard");
        console.log("you got past navigation");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during navigation:", error);
      setError(error.message);
    }
  };

  const handleLinkedInLogin = async () => {
    try {
      // Redirect to the Flask login endpoint
      window.location.href = `${process.env.REACT_APP_API_URL}/api/linkedin_login`;
    } catch (error) {
      console.error("Error during LinkedIn login:", error);
    }
  };

  // Handle the redirect after authorization
  useEffect(() => {
    const checkAuthResponse = async () => {
      const urlParams = new URLSearchParams(window.location.search);

      // Check if LinkedIn returned a profile response
      if (urlParams.has("access_token")) {
        const accessToken = urlParams.get("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/initiate_linkedin`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.status === 200) {
          const userData = response.data;
          console.log("UID:", userData.id);
          console.log("First Name:", userData.firstName);
          console.log("Last Name:", userData.lastName);
          console.log("Email:", userData.emailAddress);

          // Redirect to candidate portal
          navigate("/candidate-portal"); // Use your React Router navigation here
        }
      }
    };

    checkAuthResponse();
  }, []); // This will run when the component mounts

  return (
    <div className="container mx-auto">
      <div className="md:mt-0 sm:h-16 lg:mt-0 xl:mt-8 w-full mx-4 rounded-md lg:h-2 xl:h-20"></div>
      {/* Layout for small and medium screens */}
      <div
        style={{ height: "100vh" }}
        className="block lg:hidden  pb-8 ml-6 mx-5 rounded-md dark:bg-gray-800 dark:text-white"
      >
        <div className="flex flex-col gap-2"></div>
        <div className="flex flex-col gap-3 dark:text-gray-200 text-start pt-16">
          <h1 className="bg-clip-text text-5xl font-medium text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
            Sign In
          </h1>
          <h2 className="text-3xl text-gray-600 dark:text-gray-400">
            Please select the portal you would like to login to.
          </h2>
          <h2 className="text-xl text-gray-600 dark:text-gray-300 mb-8">
            If you are a new user, not to worry, you'll be automatically signed
            up if our system doesn't recognize you.
          </h2>
        </div>
        <div className="  flex flex-col items-center space-y-4">
          <a
            className="bg-gray-100 dark:bg-gray-700 flex w-full max-w-xl items-center text-lg p-5 rounded border transition duration-300 ease-in-out shadow-md hover:bg-gray-200 hover:border-transparent hover:cursor-pointer"
            onClick={() => handleLoginselection("Admin")}
          >
            <div>
              <div className="text-lg font-semibold leading-snug tracking-tight mb-1 dark:text-gray-200">
                Admin Portal
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Take collaboration to the next level with security and
                administrative features built for teams.
              </div>
            </div>
            <div className="flex justify-center dark:bg-gray-800 items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
            </div>
          </a>

          <a
            className="bg-gray-100 dark:bg-gray-700 flex w-full max-w-xl items-center text-lg p-5 rounded border transition duration-300 ease-in-out shadow-md hover:bg-gray-200 hover:border-transparent hover:cursor-pointer"
            onClick={() => handleLoginselection("Candidate")}
          >
            <div>
              <div className="text-lg font-semibold leading-snug dark:text-gray-200 tracking-tight mb-1">
                Candidate Portal
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Take collaboration to the next level with security and
                administrative features built for teams.
              </div>
            </div>
            <div className="flex justify-center dark:bg-gray-800 items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                />
              </svg>
            </div>
          </a>

          <a
            className="bg-gray-100 dark:bg-gray-700 flex w-full max-w-xl items-center text-lg p-5 rounded border transition duration-300 ease-in-out shadow-md hover:bg-gray-200 dark:hover:bg-gray-50 hover:border-transparent hover:cursor-pointer"
            onClick={() => handleLoginselection("Employer")}
          >
            <div>
              <div className="text-lg font-semibold leading-snug dark:text-gray-200 tracking-tight mb-1">
                Employer Portal
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Take collaboration to the next level with security and
                administrative features built for teams.
              </div>
            </div>
            <div className="flex justify-center dark:bg-gray-800 items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>

      {/* Layout for Large and Extra Large screens */}
      <div
        style={{ height: "100vh" }}
        className="hidden lg:flex flex-row items-center lg:w-8/12 gap-10 mx-auto"
      >
        <div className="flex flex-col gap-3 lg:w-10/12 dark:text-gray-200 text-start pt-8">
          <h1 className="font-extrabold text-4xl ">Hi There!</h1>
          <h2 className="text-lg mt-4">
            Please select the portal you would like to login to.
          </h2>
          <h2 className="font-bold text-medium mt-2">
            If you are a new user, not to worry, you'll be automatically signed
            up if our system doesn't recognize you.
          </h2>
        </div>

        <div className="mt-8  flex flex-col items-center space-y-4">
          <a
            className="flex sm:w-36 md:w-96 w-full max-w-xl items-center text-lg p-5 rounded border transition duration-300 ease-in-out shadow-md hover:bg-gray-200 hover:border-transparent hover:cursor-pointer"
            onClick={() => handleLoginselection("Admin")}
          >
            <div>
              <div className="text-lg font-semibold leading-snug tracking-tight mb-1 dark:text-gray-200">
                Admin Portal
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Take collaboration to the next level with security and
                administrative features built for teams.
              </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
            </div>
          </a>

          <a
            className="flex w-full max-w-xl items-center text-lg p-5 rounded border transition duration-300 ease-in-out shadow-md hover:bg-gray-200 hover:border-transparent hover:cursor-pointer"
            onClick={() => handleLoginselection("Candidate")}
          >
            <div>
              <div className="text-lg font-semibold leading-snug dark:text-gray-200 tracking-tight mb-1">
                Candidate Portal
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Take collaboration to the next level with security and
                administrative features built for teams.
              </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                />
              </svg>
            </div>
          </a>

          <a
            className="flex w-full max-w-xl items-center text-lg p-5 rounded border transition duration-300 ease-in-out shadow-md hover:bg-gray-200 dark:hover:bg-gray-50 hover:border-transparent hover:cursor-pointer"
            onClick={() => handleLoginselection("Employer")}
          >
            <div>
              <div className="text-lg font-semibold leading-snug dark:text-gray-200 tracking-tight mb-1">
                Employer Portal
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Take collaboration to the next level with security and
                administrative features built for teams.
              </div>
            </div>
            <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
