// src/components/Home.jsx
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import chatBackgroundImage from "../../assets/images/dashboard.jpg";
import axios from "axios";
import { LineChart } from "@mui/x-charts/LineChart";
import { useLocation } from "react-router-dom";
import { useCUser } from "../../contexts/Cusercontext";

export default function Dashboard() {
  const { setUser } = useCUser();
  const { setFirst } = useCUser();
  const { setLast } = useCUser();
  const { setEmail } = useCUser();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const userId = query.get("user_id");
  const email = query.get("email");
  const firstName = query.get("first_name"); // Get first_name from query parameters

  useEffect(() => {
    if (firstName) {
      setFirst(firstName); // Set first name in global context
      console.log("Firstname set in global context as:", firstName);
    }
  }, [firstName, setFirst]);

  // Funtion to handle the fetching of subjects
  return (
    <div className="container ml-auto mr-auto p-0">
      {/* Template for small screens */}
      <div className="block sm:hidden p-2 ml-3 mr-3 rounded-md">
        <h1 className="text-3xl font-light dark:text-gray-300 mt-4">
          Trending Topics
        </h1>
        <div className="flex flex-row gap-2 bg-gray-200 rounded-md mt-4 p-2 ">
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-center gap-2 text-base font-semibold">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
                alt="Amazon Logo"
                className="w-14 h-5 mt-2"
              />
              <h1 className="text-xl">Product Manager</h1>
            </div>

            <h1 className="text-lg font-light">Product | Product Design</h1>
            <h1 className="text-lg font-base">Cost: 2TKNS</h1>
          </div>
          <div className="bg-black w-12 h-20 rounded-md ml-auto flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-stone-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
              />
            </svg>
          </div>
        </div>

        <h1 className="text-3xl font-light dark:text-gray-300 mt-4">
          Recommended Topics
        </h1>
        <div className="bg-gray-200 rounded-md mt-4 p-2 ">
          <h2 className="text-lg font-bold">Small Screen Template</h2>
          <p>This template is visible on small screens (sm and below).</p>
        </div>

        <h1 className="text-3xl font-light dark:text-gray-300 mt-4">
          Refer a Friend
        </h1>
        <div className="bg-stone-200 rounded-md flex flex-col p-2 mt-4">
          <h1 className="text-xl font-semibold ml-2 mt-2">Refer a friend</h1>
          <h2 className="text-sm font-light ml-2">
            Refer a friend using your code and gain 200 TKNS for free
          </h2>
          <div className="bg-stone-400 rounded-md mt-2 flex flex-row ml-2 mr-2">
            <h1 className=" ml-2 p-2 w-36 rounded-md font-semibold text-sm mt-0.5">
              Guest2371521
            </h1>
            <button className="ml-2 bg-black text-stone-200 w-12 rounded-md h-10 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                />
              </svg>
            </button>
          </div>
          <hr className="h-0.5 mt-1 mr-2 rounded-md bg-black ml-2 mt-4"></hr>
          <h1 className="text-sm font-light ml-2 mt-2">
            *You can only gain tokens for a maximum of 5 referred users
          </h1>
        </div>
      </div>

      <div className="hidden lg:flex flex-row">
        {/* Recommended Sections */}
        <div className="">
          <h1 className="ml-4 text-lg font-semibold">
            Recommended Simulations
          </h1>
          <div className="bg-stone-200 w-96 h-20 ml-4 rounded-md p-2 flex flex-row gap-4 mt-2">
            <div className="bg-stone-300 w-16 h-16 rounded-md"></div>
            <div className="flex flex-col">
              <h1 className="text-base font-semibold">Product Manager</h1>
              <h1 className="text-sm font-light">Product | Product Design</h1>
              <h1 className="text-sm font-base">Cost: 2TKNS</h1>
            </div>
            <div className="bg-black w-12 h-16 rounded-md ml-auto flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-stone-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                />
              </svg>
            </div>
          </div>
          <div className="bg-stone-200 w-96 h-20 ml-4 rounded-md p-2 flex flex-row gap-4 mt-2">
            <div className="bg-stone-300 w-16 h-16 rounded-md"></div>
            <div className="flex flex-col">
              <h1 className="text-base font-semibold">Product Manager</h1>
              <h1 className="text-sm font-light">Product | Product Design</h1>
              <h1 className="text-sm font-base">Cost: 2TKNS</h1>
            </div>
            <div className="bg-black w-12 h-16 rounded-md ml-auto flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-stone-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Progress Sections */}
        <div className="ml-4 mr-8" style={{ width: "42%" }}>
          <h1 className="ml-4 w-96 text-lg font-semibold">My Progress</h1>
          <div
            style={{ width: "100%" }}
            className="bg-stone-200 w-96 h-20 ml-4 rounded-md p-2 flex flex-row gap-4 mt-2"
          >
            <div className="bg-stone-300 w-16 h-16 rounded-md"></div>
            <div className="flex flex-col">
              <h1 className="text-base font-semibold">Product Manager</h1>
              <h1 className="text-sm font-light">Product | Product Design</h1>
              <h1 className="text-sm font-base">Cost: 2TKNS</h1>
            </div>
            <div className="bg-black w-12 h-16 rounded-md ml-auto flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-stone-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Refer a Friend Section */}
        <div className="flex flex-col">
          <div className="bg-stone-200 w-56 h-56 rounded-md ml-4 flex flex-col p-2">
            <h1 className="text-xl font-semibold ml-2 mt-2">Refer a friend</h1>
            <h2 className="text-sm font-light ml-2">
              Refer a friend using your code and gain 200 TKNS for free
            </h2>
            <div className="bg-stone-400 rounded-md mt-2 flex flex-row ml-2 mr-2">
              <h1 className=" ml-2 p-2 w-36 rounded-md font-semibold text-sm mt-0.5">
                Guest2371521
              </h1>
              <button className="ml-2 bg-black text-stone-200 w-12 rounded-md h-10 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                  />
                </svg>
              </button>
            </div>
            <hr className="h-0.5 mt-1 mr-2 rounded-md bg-black ml-2 mt-4"></hr>
            <h1 className="text-sm font-light ml-2 mt-2">
              *You can only gain tokens for a maximum of 5 referred users
            </h1>
          </div>

          <div className="bg-stone-200 w-56 h-64 rounded-md ml-4 mt-4 flex flex-col p-2">
            <h1 className="text-xl font-semibold ml-2 mt-2">My Token Usage</h1>

            {/* Chart component */}
            {/* Chart component */}

            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                },
              ]}
              margin={{
                left: 35,
                right: 20,
                top: 20,
                bottom: 20,
              }}
            />

            <hr className="h-0.5 mt-1 mr-2 rounded-md bg-black ml-2 mt-4"></hr>
            <h1 className="text-lg font-semibold ml-2 mt-2 text-center">
              June
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
